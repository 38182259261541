import mapDataIE from "@highcharts/map-collection/countries/br/br-all.geo.json";

const states = [
    "br-sp",
    "br-ma",
    "br-pa",
    "br-sc",
    "br-ba",
    "br-ap",
    "br-ms",
    "br-mg",
    "br-go",
    "br-rs",
    "br-to",
    "br-pi",
    "br-al",
    "br-pb",
    "br-ce",
    "br-se",
    "br-rr",
    "br-pe",
    "br-pr",
    "br-es",
    "br-rj",
    "br-rn",
    "br-am",
    "br-mt",
    "br-df",
    "br-ac",
    "br-ro",
];

function formatFakeSalesMap() {
    const data = states.map((i) => [i, Number((Math.random() * 100).toFixed(0))]);

    return {
        chart: {
            map: "countries/br/br-all",
        },

        title: {
            text: "",
        },
        credits: {
            enabled: false,
        },

        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: "bottom",
            },
        },

        colorAxis: {
            min: 0,
        },

        series: [
            {
                data: data,
                name: "Quantidade",
                mapData: mapDataIE,
                states: {
                    hover: {
                        color: "#BADA55",
                    },
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.name}",
                },
            },
        ],
    };
}

export default formatFakeSalesMap;
