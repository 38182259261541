import getData from './data';
import * as R from 'ramda';
import {formatProfileStructure} from '../../../formatdata';
import formatPageForRouter from '../../../formatdata/formatPageForRouter';

export function formatFakeSidebarStructure() {
  return formatProfileStructure(demo_clothing_company_structure);
}

export function getFakePages() {
  return formatPageForRouter(formatFakeSidebarStructure());
}


// <iframe
//     width="960"
//     height="720"
//     src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/767397999763/dashboards/595f582b-4fde-4abb-96d2-e060ee046535?directory_alias=uw">
// </iframe>

const demo_clothing_company_structure = {
  'pages': [
    {
      'name': 'Comercial',
      'path': 'commercial',
      'icon': 'CommercialIcon',
      'pages': [
        {
          'name': 'Vendas',
          'path': 'sales',
          'icon': 'ProductionIcon',
          'gridRowMin': 'auto',
          'gridRowMax': 'auto',
          // 'page_type': 'iframe',
          // 'quicksight_account_id': '767397999763',
          // 'quicksight_dashboard_id': 'd96f5f75-a0d0-4576-8dd0-7565f029eaf0',
          // 'metabase_url': 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/767397999763/dashboards/595f582b-4fde-4abb-96d2-e060ee046535?directory_alias=uw',
          'rows': [
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Mapa',
                  'widgetType': 'map',
                  'disableTitle': false,
                  'widgetParams': {},
                  'formatData': 'formatFakeSalesMap',
                },
                {
                  'title': 'Card',
                  'widgetType': 'card',
                  'disableTitle': true,
                  'formatData': 'formatFakeAccuracy',
                },
              ],
            },
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Predição de vendas mensal',
                  'widgetType': 'graph',
                  'widgetParams': {},
                  'formatData': 'formatFakeSalesMonth',
                },
              ],
            },
            // {
            //   'rowSizeMin': '400px',
            //   'rowSizeMax': 'auto',
            //   'columnSizeMin': '100px',
            //   'columnSizeMax': '1fr',
            //   'widgets': [
            //     {
            //       'title': 'Predição de vendas por semana',
            //       'widgetType': 'graph',
            //       'widgetParams': {},
            //       'formatData': 'formatFakeSalesWeek',
            //     },
            //   ],
            // },
          ],
        },
        {
          'name': 'Eventos',
          'path': 'events',
          'icon': 'Event',
          'gridRowMin': 'auto',
          'gridRowMax': 'auto',
          'rows': [
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '500px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Impacto dos eventos',
                  'widgetType': 'graph',
                  'widgetParams': {},
                  'formatData': 'formatFakeEvent',
                },
              ],
            },
          ],
        },
        {
          'name': 'Preços',
          'path': 'prices',
          'icon': 'LocalOffer',
          'gridRowMin': 'auto',
          'gridRowMax': 'auto',
          'rows': [
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '500px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Impacto dos eventos',
                  'widgetType': 'graph',
                  'widgetParams': {},
                  'formatData': 'formatFakePrice',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      'name': 'Estoque',
      'path': 'stock',
      'icon': 'StockIcon',
      'pages': [
        {
          'name': 'Remanejamento',
          'path': 'relocation',
          'icon': 'RelocationIcon',
          'pages': [
            {
              'name': 'Entrada',
              'path': 'input',
              'icon': 'ProductionIcon',
              'gridRowMin': 'auto',
              'gridRowMax': 'auto',
              'rows': [
                // {
                //     "rowSizeMin": "400px",
                //     "rowSizeMax": "auto",
                //     "columnSizeMin": "100px",
                //     "columnSizeMax": "1fr",
                //     "widgets": [
                //         {
                //             "title": "Ganho no faturamento",
                //             "widgetType": "graph",
                //             "disableTitle": true,
                //             "formatData": "formatFakeRelocation"
                //         }
                //     ]
                // },
                {
                  'rowSizeMin': '400px',
                  'rowSizeMax': 'auto',
                  'columnSizeMin': '100px',
                  'columnSizeMax': '1fr',
                  'widgets': [
                    {
                      'title': 'Card',
                      'widgetType': 'accordion',
                      'disableTitle': true,
                      'formatData': 'formatFakeAccordion',
                      'widgetParams': {
                        'labels': R.toPairs(R.groupBy(R.props(['FROM', 'TO']),
                            getData().relocation_input)).map((item) => {
                          const [key, value] = item;

                          const values = R.map(R.dissoc('TO'),
                              R.map(R.dissoc('FROM'), value));

                          const stores = R.split(',', key);

                          return {
                            'name': 'TRANSFERENCIA DA LOJA: ' + stores[0] +
                                ' - PARA: ' + stores[1],
                            'widget': {
                              'title': 'Card',
                              'widgetType': 'table',
                              'disableTitle': true,
                              'formatData': 'formatFakeTable-' +
                                  JSON.stringify({
                                    headers: [
                                      'PRODUTO',
                                      'PREDIÇÃO',
                                      'TRANSFERIDO'],
                                    rows: R.map(R.values, values),
                                  }),
                            },
                          };
                        }),
                      },
                    },
                  ],
                },
              ],
            },
            {
              'name': 'Saída',
              'path': 'output',
              'icon': 'ProductionIcon',
              'gridRowMin': 'auto',
              'gridRowMax': 'auto',
              'rows': [
                {
                  'rowSizeMin': '400px',
                  'rowSizeMax': '400px',
                  'columnSizeMin': '100px',
                  'columnSizeMax': '1fr',
                  'widgets': [
                    {
                      'title': 'Card',
                      'widgetType': 'accordion',
                      'disableTitle': true,
                      'formatData': 'formatFakeAccordion',
                      'widgetParams': {
                        'labels': R.toPairs(R.groupBy(R.props(['FROM', 'TO']),
                            getData().relocation_output)).map((item) => {
                          const [key, value] = item;

                          const values = R.map(R.dissoc('TO'),
                              R.map(R.dissoc('FROM'), value));

                          const stores = R.split(',', key);

                          return {
                            'name': 'TRANSFERENCIA DA LOJA: ' + stores[0] +
                                ' - PARA: ' + stores[1],
                            'widget': {
                              'title': 'Card',
                              'widgetType': 'table',
                              'disableTitle': true,
                              'formatData': 'formatFakeTable-' +
                                  JSON.stringify({
                                    headers: [
                                      'PRODUTO',
                                      'PREDIÇÃO',
                                      'TRANSFERIDO'],
                                    rows: R.map(R.values, values),
                                  }),
                            },
                          };
                        }),
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          'name': 'Promoção',
          'path': 'promotion',
          'icon': 'PromotionIcon',
          'gridRowMin': 'auto',
          'gridRowMax': 'auto',
          'rows': [
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Ganho no faturamento',
                  'widgetType': 'graph',
                  'disableTitle': true,
                  'formatData': 'formatFakePromotion',
                },
              ],
            },
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Card',
                  'widgetType': 'accordion',
                  'disableTitle': true,
                  'formatData': 'formatFakeAccordion',
                  'widgetParams': {
                    'labels': R.toPairs(
                        R.groupBy(R.prop('LOJA'), getData().promotion))
                        .map((item) => {
                          const [key, value] = item;

                          return {
                            'name': key,
                            'widget': {
                              'title': 'Card',
                              'widgetType': 'table',
                              'disableTitle': true,
                              'formatData': 'formatFakeTable-' +
                                  JSON.stringify({
                                    headers: ['PRODUTO', 'PREDIÇÃO', 'ENVIADO'],
                                    rows: R.map(R.values,
                                        R.map(R.dissoc('LOJA'), value)),
                                  }),
                            },
                          };
                        }),
                  },
                },
              ],
            },
          ],
        },
        {
          'name': 'Abastecimento',
          'path': 'supply',
          'icon': 'SupplyIcon',
          'gridRowMin': 'auto',
          'gridRowMax': 'auto',
          'rows': [
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Ganho no faturamento',
                  'widgetType': 'graph',
                  'disableTitle': true,
                  'formatData': 'formatFakeSupply',
                },
              ],
            },
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Card',
                  'widgetType': 'accordion',
                  'disableTitle': true,
                  'formatData': 'formatFakeAccordion',
                  'widgetParams': {
                    'labels': R.toPairs(
                        R.groupBy(R.prop('LOJA'), getData().supply))
                        .map((item) => {
                          const [key, value] = item;

                          return {
                            'name': key,
                            'widget': {
                              'title': 'Card',
                              'widgetType': 'table',
                              'disableTitle': true,
                              'formatData': 'formatFakeTable-' +
                                  JSON.stringify({
                                    headers: ['PRODUTO', 'PREDIÇÃO', 'COMPRA'],
                                    rows: R.map(R.values,
                                        R.map(R.dissoc('LOJA'), value)),
                                  }),
                            },
                          };
                        }),
                  },
                },
              ],
            },
          ],
        },
        {
          'name': 'Produção/Compras',
          'path': 'production',
          'icon': 'ProductionIcon',
          'gridRowMin': 'auto',
          'gridRowMax': 'auto',
          'rows': [
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Ganho no faturamento',
                  'widgetType': 'graph',
                  'disableTitle': true,
                  'formatData': 'formatFakeProductionAndPurchase',
                },
              ],
            },
            {
              'rowSizeMin': '400px',
              'rowSizeMax': 'auto',
              'columnSizeMin': '100px',
              'columnSizeMax': '1fr',
              'widgets': [
                {
                  'title': 'Card',
                  'widgetType': 'accordion',
                  'disableTitle': true,
                  'formatData': 'formatFakeProduction',
                  'widgetParams': {
                    'labels': getData().suppliers.map(supplier => {
                      const {headers, products} = supplier;

                      const supplierString = JSON.stringify({
                        headers,
                        rows: R.map(R.values, products),
                      });

                      return {
                        'name': supplier.name,
                        'widget': {
                          'title': 'Card',
                          'widgetType': 'table',
                          'disableTitle': true,
                          'formatData': 'formatFakeTable-' + supplierString,
                        },
                      };
                    }),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default demo_clothing_company_structure;
