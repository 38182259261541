import styled from 'styled-components'

export const ContainerPageStyled = styled.div`
  height: calc(100% - 102px);
  width: calc(100% - 22px);
  display: grid;
  grid-template-rows: repeat(
    ${props => props.rowsLength},
    minmax(
      ${props => props.gridRowMin},
      ${props => props.gridRowMax}
    )
  );
  grid-gap: 10px;
  padding: 10px;

  @media (max-width: 800px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
    grid-template-rows: none;
  }
`

export const ContainerRowStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.widgetsLength},
    minmax(
      ${props => props.columnSizeMin},
      ${props => props.columnSizeMax}
    )
  );
  grid-template-rows: repeat(
    1,
    minmax(
      ${props => props.rowSizeMin},
      ${props => props.rowSizeMax}
    )
  );
  grid-gap: 10px;
  @media (max-width: 800px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
    grid-template-columns: repeat(
      1,
      minmax(
        auto,
        ${props => props.columnSizeMax}
      )
    );
  }
`
