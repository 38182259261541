import overviewBestSellingColorQuery from "./overviewBestSellingColorQuery";
import overviewBestSellingGroupQuery from "./overviewBestSellingGroupQuery";
import overviewBestSellingProductQuery from "./overviewBestSellingProductQuery";
import overviewBestSellingSizeQuery from "./overviewBestSellingSizeQuery";
import overviewBestSellingStoreQuery from "./overviewBestSellingStoreQuery";
import overviewGrossAmountQuery from "./overviewGrossAmountQuery";
import overviewNetAmountQuery from "./overviewNetAmountQuery";
import overviewQuantityQuery from "./overviewQuantityQuery";
import predictionGridListQuery from "./predictionGridListQuery";
import predictionSaleListQuery from "./predictionSaleListQuery";
import predictionMapStoreListQuery from "./predictionMapStoreListQuery";
import predictionSaleListMonthQuery from "./predictionSaleListMonthQuery";

export default {
  overviewBestSellingColorQuery,
  overviewBestSellingGroupQuery,
  overviewBestSellingProductQuery,
  overviewBestSellingSizeQuery,
  overviewBestSellingStoreQuery,
  overviewGrossAmountQuery,
  overviewNetAmountQuery,
  overviewQuantityQuery,
  predictionGridListQuery,
  predictionSaleListQuery,
  predictionMapStoreListQuery,
  predictionSaleListMonthQuery,
};
