import {UserManager} from 'oidc-client';

const protocol = window.location.protocol
const host = window.location.host

export const DASHBOARD_URL = process.env?.REACT_DASHBOARD_URL ?
    process.env?.REACT_DASHBOARD_URL :
    `${protocol}//${host}`;

const userManager = new UserManager({
  authority: `http://auth.gcp.upowl.com.br/realms/upowl`,
  client_id: "dash",
  client_secret: "3tLmg0i9qNVWpWTGnAa6YiD43AXEXp3g",
  redirect_uri: `${DASHBOARD_URL}/callback`,
  response_type: "code",
  scope: "openid profile",
  post_logout_redirect_uri: `${DASHBOARD_URL}/callback`,
  // automaticSilentRenew: true,
});

export default userManager;
