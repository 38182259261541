import formatFakeSalesMap from "./formatFakeSalesMap";
import formatFakeSalesWeek from "./formatFakeSalesWeek";
import formatFakeSalesMonth from "./formatFakeSalesMonth";
import formatFakeAccuracy from "./formatFakeAccurracy";
import formatFakeEvent from "./formatFakeEvent";
import formatFakePrice from "./formatFakePrice";
import formatFakeRelocation from "./formatFakeRelocation";
import formatFakePromotion from "./formatFakePromotion";
import formatFakeSupply from "./formatFakeSupply";
import formatFakeProductionAndPurchase from "./formatFakeProductionAndPurchase";
import formatFakeTable from "./formatFakeTable";

export default {
    formatFakeSalesMap,
    formatFakeSalesWeek,
    formatFakeSalesMonth,
    formatFakeAccuracy,
    formatFakeEvent,
    formatFakePrice,
    formatFakeRelocation,
    formatFakePromotion,
    formatFakeSupply,
    formatFakeProductionAndPurchase,
    formatFakeTable,
};
