import React, {useContext} from 'react';
import {
  AppBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {ExitToApp} from '@material-ui/icons';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import {MenuContext} from '../../state/MenuContextProvider';
import {AnalyticsContext} from '../../state/AnalyticsContextProvider';
import {LoaderContext} from '../../state/LoadingContextProvider';
import {useAuth} from 'react-oidc-context';

const useStyles = makeStyles({
  sidebarContent: {
    padding: '0 20px 0 5px',
    flex: 1,
  },
});

function DrawerComponent() {
  const auth = useAuth();
  const classes = useStyles();

  const {
    analyticsMenuPages,
    setAnalyticsMenuPages,
  } = useContext(MenuContext);

  const {structure} = useContext(AnalyticsContext);

  const {loading} = useContext(LoaderContext);

  async function logout() {
    try {
      await auth.signoutRedirect();
      window.location.href = '/login';
    } catch (error) {
      console.error('LOGOUT ERROR: ', error);
    }
  }

  return (
      <SwipeableDrawer
          anchor={'left'}
          open={analyticsMenuPages}
          onClose={() => setAnalyticsMenuPages(false)}
          onOpen={() => setAnalyticsMenuPages(true)}
      >
        <AppBar
            position="relative"
            // className={classes.appBar}
            elevation={0}
            color="primary"
        >
          <Toolbar>
            <Typography>Menu</Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.sidebarContent}>
          <SidebarComponent
              open={analyticsMenuPages}
              loading={loading}
              structure={structure}
          />
        </div>
        <List component="nav">
          <ListItem
              button
              // selected={selectedIndex === 0}
              onClick={logout}
          >
            <ListItemIcon>
              <ExitToApp/>
            </ListItemIcon>
            <ListItemText primary="Sair"/>
          </ListItem>
        </List>
      </SwipeableDrawer>
  );
}

export default DrawerComponent;
