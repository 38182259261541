import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { FilterContext } from "../../../state/FilterContextProvider";
import { getFilterProvider } from "../../../providers/filterProvider";
import * as R from "ramda";
import { Divider, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function FilterTableComponent({ value, name, state, query, typeReturn }) {
  const classes = useStyles();

  const { variables, setVariables } = useContext(FilterContext);

  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");

  const updateFilter = (i) => setVariables({ ...variables, [state]: i });

  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, search]);

  async function getItems() {
    const data = await getFilterProvider(
      query,
      {
        offset: 0,
        limit: 20,
        search,
      },
      typeReturn
    );

    if (Array.isArray(data)) {
      setItems(data);
    }
  }

  const handleToggle = (item) => () => {
    if (
      R.includes(
        item.ID,
        variables[state].map((i) => i.ID)
      )
    ) {
      updateFilter(variables[state].filter((i) => i.ID !== item.ID));
    } else {
      updateFilter(R.append(item, variables[state]));
    }
  };

  const handlerSearch = (e) => setSearch(e.target.value);

  return (
    <div
      style={{
        margin: 10,
        width: "calc(100%-20px)",
        height: "calc(100%-20px)",
        overflow: "auto",
      }}
    >
      <Typography variant="h5" component="h5" color="primary">
        {name}
      </Typography>
      <Divider />

      <TextField
        id={`filter-search-${value}`}
        fullWidth
        label="Procurar..."
        value={search}
        onChange={handlerSearch}
      />

      <List className={classes.root}>
        {items.map((item, index) => {
          const labelId = `checkbox-list-label-${index}`;

          return (
            <ListItem
              key={labelId}
              role={undefined}
              dense
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={R.includes(
                    item.ID,
                    variables[state].map((i) => i.ID)
                  )}
                  // checked={false}
                  tabIndex={-1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.Name} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export default FilterTableComponent;
