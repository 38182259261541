import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import {Divider, Toolbar, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function TableComponent({title, columns, items, tableOptions}) {
    const classes = useStyles();

    const columnCount = columns?.length;

    return (
        <Paper className={classes.paper}>
            {title ? (
                <Toolbar>
                    <Typography className={classes.title} variant="h6" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            ) : null}

            <Divider />

            <TableContainer>
                <Table {...tableOptions} className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column, index) => {
                                let align = 'left';
                                if (index + 1 === columnCount) {
                                    align = 'right';
                                }

                                return (
                                    <TableCell
                                        key={`column-${index}-${column}`}
                                        align={align}
                                    >
                                        {column}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items?.map((row, index) => {
                            const rowLength = row?.length;

                            return (
                                <TableRow key={`items-row-${index}`}>
                                    {row?.map((item, indexItem) => {
                                        let align = 'left';
                                        if (indexItem + 1 === rowLength) {
                                            align = 'right';
                                        }

                                        return (
                                            <TableCell
                                                key={`items-row-${index}-${indexItem}`}
                                                align={align}
                                            >
                                                <CellRenderer item={item}/>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

function CellRenderer({item}) {
    if (typeof item === 'string')
        return (<Typography>{item}</Typography>)
    else if (typeof item === 'number')
        return (<Typography>{`${item}`}</Typography>)
    else if (typeof item === 'boolean')
        return (<Typography>{`${item}`}</Typography>)
    else
        return item
}

TableComponent.prototype = {
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
}

export default TableComponent;
