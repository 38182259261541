import React from "react";
import * as R from "ramda";
import ParentSidebarComponent from "./ParentSidebarComponent/ParentSidebarComponent";
import LinkSidebarComponent from "./LinkSidebarComponent/LinkSidebarComponent";

function RecursiveSidebarComponent({
  open,
  marginLeft = 0,
  pages,
  parentPath = [],
}) {
  return (
    <>
      {pages.map((page, iPage) => {
        const newParentPath = R.append(page.path, parentPath);

        if (page.pages && page.pages.length > 0) {
          return (
            <ParentSidebarComponent
              key={iPage}
              marginLeft={marginLeft}
              open={open}
              {...page}
            >
              <RecursiveSidebarComponent
                marginLeft={10}
                {...{ open, pages: page.pages, parentPath: newParentPath }}
              />
            </ParentSidebarComponent>
          );
        }

        return (
          <LinkSidebarComponent key={iPage} {...{ open, page, parentPath }} />
        );
      })}
    </>
  );
}

export default RecursiveSidebarComponent;
