import {
  Business,
  Category,
  DateRange,
  Event,
  Group,
  Home,
  LocalOffer,
  MultilineChart,
  Palette,
  Straighten,
} from '@material-ui/icons';
import IaIcon from '../icons/IaIcon';
import StockIcon from '../icons/StockIcon';
import ProductionIcon from '../icons/ProductionIcon';
import RelocationIcon from '../icons/RelocationIcon';
import PromotionIcon from '../icons/PromotionIcon';
import SupplyIcon from '../icons/SupplyIcon';
import CommercialIcon from '../icons/CommercialIcon';
import SaleIcon from '../icons/SaleIcon';

export default {
  Home,
  MultilineChart,
  DateRange,
  Group,
  Category,
  Business,
  Palette,
  Straighten,
  IaIcon,
  StockIcon,
  ProductionIcon,
  Event,
  LocalOffer,
  RelocationIcon,
  PromotionIcon,
  SupplyIcon,
  CommercialIcon,
  SaleIcon,
};
