const getData = () => {

    const ramdom = Math.floor(Math.random() * 20)

    return {
        suppliers: [
            {
                name: "SADIA",
                headers: ["PRODUTO", "MÉDIA", "PREDIÇÃO", "COMPRA"],
                products: [
                    {
                        name: "Presunto cozido fatiado 180g",
                        average: 40 + ramdom,
                        prediction: 45 + ramdom,
                        quantity_purchase: 45 + ramdom,
                    },
                    {
                        name: "Mortadela fatiada defumada 180g",
                        average: 30 + ramdom,
                        prediction: 35 + ramdom,
                        quantity_purchase: 35 + ramdom,
                    },
                    {
                        name: "Pepperoni fatiado",
                        average: 25 + ramdom,
                        prediction: 20 + ramdom,
                        quantity_purchase: 20 + ramdom,
                    },
                    {
                        name: "Apresuntado retangular",
                        average: 7 + ramdom,
                        prediction: 5 + ramdom,
                        quantity_purchase: 5 + ramdom,
                    },
                ]
            },
            {
                name: "AMBEV",
                headers: ["PRODUTO", "MÉDIA", "PREDIÇÃO", "COMPRA"],
                products: [
                    {
                        name: "Brahma CX",
                        average: 40 + ramdom,
                        prediction: 45 + ramdom,
                        quantity_purchase: 45 + ramdom,
                    },
                    {
                        name: "Budweiser CX",
                        average: 30 + ramdom,
                        prediction: 35 + ramdom,
                        quantity_purchase: 35 + ramdom,
                    },
                    {
                        name: "Antarctica CX",
                        average: 65 + ramdom,
                        prediction: 60 + ramdom,
                        quantity_purchase: 60 + ramdom,
                    },
                    {
                        name: "Bohemia CX",
                        average: 38 + ramdom,
                        prediction: 35 + ramdom,
                        quantity_purchase: 35 + ramdom,
                    },
                ]
            },
            {
                name: "UNILEVER",
                headers: ["PRODUTO", "MÉDIA", "PREDIÇÃO", "COMPRA"],
                products: [
                    {
                        name: "Ala",
                        average: 34 + ramdom,
                        prediction: 40 + ramdom,
                        quantity_purchase: 40 + ramdom,
                    },
                    {
                        name: "Brilhante",
                        average: 26 + ramdom,
                        prediction: 25 + ramdom,
                        quantity_purchase: 25 + ramdom,
                    },
                    {
                        name: "CIF",
                        average: 18 + ramdom,
                        prediction: 20 + ramdom,
                        quantity_purchase: 20 + ramdom,
                    },
                    {
                        name: "OMO",
                        average: 32 + ramdom,
                        prediction: 40 + ramdom,
                        quantity_purchase: 40 + ramdom,
                    },
                ]
            }
        ],
        supply: [
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Presunto cozido fatiado 180g",
                "PREDICAO": 45,
                "COMPRA": 45
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Mortadela fatiada defumada 180g",
                "PREDICAO": 35,
                "COMPRA": 35
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Pepperoni fatiado",
                "PREDICAO": 20,
                "COMPRA": 20
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Apresuntado retangular",
                "PREDICAO": 5,
                "COMPRA": 5
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Budweiser CX",
                "PREDICAO": 30,
                "COMPRA": 30
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Antarctica CX",
                "PREDICAO": 60,
                "COMPRA": 60
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Bohemia CX",
                "PREDICAO": 35,
                "COMPRA": 35
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Ala",
                "PREDICAO": 40,
                "COMPRA": 40
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Brilhante",
                "PREDICAO": 25,
                "COMPRA": 25
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Presunto cozido fatiado 180g",
                "PREDICAO": 45,
                "COMPRA": 45
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Mortadela fatiada defumada 180g",
                "PREDICAO": 35,
                "COMPRA": 35
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Pepperoni fatiado",
                "PREDICAO": 20,
                "COMPRA": 20
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Apresuntado retangular",
                "PREDICAO": 5,
                "COMPRA": 5
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Budweiser CX",
                "PREDICAO": 30,
                "COMPRA": 30
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Antarctica CX",
                "PREDICAO": 60,
                "COMPRA": 60
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Bohemia CX",
                "PREDICAO": 35,
                "COMPRA": 35
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Brahma CX",
                "PREDICAO": 45,
                "COMPRA": 45
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "OMO",
                "PREDICAO": 40,
                "COMPRA": 40
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Mortadela fatiada defumada 180g",
                "PREDICAO": 35,
                "COMPRA": 35
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Pepperoni fatiado",
                "PREDICAO": 20,
                "COMPRA": 20
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Apresuntado retangular",
                "PREDICAO": 5,
                "COMPRA": 5
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Budweiser CX",
                "PREDICAO": 30,
                "COMPRA": 30
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Antarctica CX",
                "PREDICAO": 60,
                "COMPRA": 60
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Bohemia CX",
                "PREDICAO": 35,
                "COMPRA": 35
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Ala",
                "PREDICAO": 40,
                "COMPRA": 40
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Brilhante",
                "PREDICAO": 25,
                "COMPRA": 25
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "CIF",
                "PREDICAO": 20,
                "COMPRA": 20
            }
        ],
        promotion: [
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Presunto cozido fatiado 180g",
                "PREDICAO": 45,
                "SUGESTAO": 45
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Pepperoni fatiado",
                "PREDICAO": 20,
                "SUGESTAO": 20
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Apresuntado retangular",
                "PREDICAO": 5,
                "SUGESTAO": 5
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Budweiser CX",
                "PREDICAO": 30,
                "SUGESTAO": 30
            },
            {
                "LOJA": "MESSEJANA",
                "PRODUTO": "Brilhante",
                "PREDICAO": 25,
                "SUGESTAO": 25
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Presunto cozido fatiado 180g",
                "PREDICAO": 45,
                "SUGESTAO": 45
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Bohemia CX",
                "PREDICAO": 35,
                "SUGESTAO": 35
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "Brahma CX",
                "PREDICAO": 45,
                "SUGESTAO": 45
            },
            {
                "LOJA": "ALDEOTA",
                "PRODUTO": "OMO",
                "PREDICAO": 40,
                "SUGESTAO": 40
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Mortadela fatiada defumada 180g",
                "PREDICAO": 35,
                "SUGESTAO": 35
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Pepperoni fatiado",
                "PREDICAO": 20,
                "SUGESTAO": 20
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Antarctica CX",
                "PREDICAO": 60,
                "SUGESTAO": 60
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Bohemia CX",
                "PREDICAO": 35,
                "SUGESTAO": 35
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Ala",
                "PREDICAO": 40,
                "SUGESTAO": 40
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "Brilhante",
                "PREDICAO": 25,
                "SUGESTAO": 25
            },
            {
                "LOJA": "DUNAS",
                "PRODUTO": "CIF",
                "PREDICAO": 20,
                "SUGESTAO": 20
            }
        ],
        relocation_input: [
            {
                "FROM": "ALDEOTA",
                "TO": "MESSEJANA",
                "PRODUTO": "Presunto cozido fatiado 180g",
                "PREDICAO": 12,
                "TRANSFERIDO": 12
            },
            {
                "FROM": "ALDEOTA",
                "TO": "MESSEJANA",
                "PRODUTO": "Brahma CX",
                "PREDICAO": 10,
                "TRANSFERIDO": 10
            },
            {
                "FROM": "ALDEOTA",
                "TO": "MESSEJANA",
                "PRODUTO": "OMO",
                "PREDICAO": 5,
                "TRANSFERIDO": 5
            },
            {
                "FROM": "ALDEOTA",
                "TO": "DUNAS",
                "PRODUTO": "Mortadela fatiada defumada 180g",
                "PREDICAO": 20,
                "TRANSFERIDO": 20
            },
            {
                "FROM": "ALDEOTA",
                "TO": "DUNAS",
                "PRODUTO": "Antarctica CX",
                "PREDICAO": 28,
                "TRANSFERIDO": 28
            },
            {
                "FROM": "ALDEOTA",
                "TO": "DUNAS",
                "PRODUTO": "CIF",
                "PREDICAO": 9,
                "TRANSFERIDO": 9
            }
        ],
        relocation_output: [
            {
                "FROM": "ALDEOTA",
                "TO": "MESSEJANA",
                "PRODUTO": "Presunto cozido fatiado 180g",
                "PREDICAO": 12,
                "TRANSFERIDO": 12
            },
            {
                "FROM": "ALDEOTA",
                "TO": "MESSEJANA",
                "PRODUTO": "Brahma CX",
                "PREDICAO": 10,
                "TRANSFERIDO": 10
            },
            {
                "FROM": "ALDEOTA",
                "TO": "MESSEJANA",
                "PRODUTO": "OMO",
                "PREDICAO": 5,
                "TRANSFERIDO": 5
            },
            {
                "FROM": "ALDEOTA",
                "TO": "DUNAS",
                "PRODUTO": "Mortadela fatiada defumada 180g",
                "PREDICAO": 20,
                "TRANSFERIDO": 20
            },
            {
                "FROM": "ALDEOTA",
                "TO": "DUNAS",
                "PRODUTO": "Antarctica CX",
                "PREDICAO": 28,
                "TRANSFERIDO": 28
            },
            {
                "FROM": "ALDEOTA",
                "TO": "DUNAS",
                "PRODUTO": "CIF",
                "PREDICAO": 9,
                "TRANSFERIDO": 9
            }
        ],
    }
}

export default getData;
