export default `
  query(
    $dateStart: DateTime!
    $dateEnd: DateTime!
    $storeIDs: [Int]
    $productIDs: [Int]
  ){
    Prediction{
      MapStore(
        dateStart: $dateStart
        dateEnd: $dateEnd
        storeIDs: $storeIDs
        productIDs: $productIDs
      ){
        Key
        Quantity
      }
    }
  }
`;
