import axios from 'axios';
import {QUICKSIGHT_EMBED_URL} from "../config/url.config";


export async function getEmbedURLQuicksightProvider(token, account_id, dashboard_id) {
    const {data} = await axios.get(QUICKSIGHT_EMBED_URL, {
        params: {
            "account_id": account_id,
            "dashboard_id": dashboard_id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return data?.embed_url;
}
