import React, {createContext, useState} from 'react';
import {formatProfileStructure} from '../formatdata';
import formatPageForRouter from '../formatdata/formatPageForRouter';

export const AnalyticsContext = createContext({});

function AnalyticsContextProvider({children}) {
  const [pages, setPages] = useState([]);
  const [structure, setStructure] = useState({});

  const setNewPages = (structureString) => {
    const structure = structureString !== '' ? JSON.parse(structureString) : {};
    const structureFormatted = formatProfileStructure(structure);
    setStructure(structureFormatted);
    setPages(formatPageForRouter(structureFormatted));
  };

  return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <AnalyticsContext.Provider
          value={{
            pages,
            structure,
            setStructure: setNewPages,
          }}
      >
        {children}
      </AnalyticsContext.Provider>
  );
}

export default AnalyticsContextProvider;
