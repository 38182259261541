import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import styled from 'styled-components';
import stylesConfig from '../config/styles.config';
import DrawerComponent from '../components/DrawerComponent/DrawerComponent';
import DrawerFilterComponent
  from '../components/FilterComponents/ReponsiveFilter/DrawerFilterComponent';
import SidebarComponent from '../components/SidebarComponent/SidebarComponent';
import FilterMenuComponent
  from '../components/FilterComponents/FilterMenuComponent';
import {MenuContext} from '../state/MenuContextProvider';
import IFrameComponent from '../components/IFrameComponente';
import {TenantContext} from '../state/TenantContextProvider';
import LoadingComponent from '../components/LoadingComponent';
import isNullOrBlank from '../util/isNullOrBlank';
import {getAllPagesByTenant} from '../providers/pagesByTenantProvider';
import {useAuth} from 'react-oidc-context';
import {Redirect, Route} from 'react-router-dom';
import {ROUTE_PATH_ANALYTICS} from '../defines';
import {
  formatFakeSidebarStructure,
  getFakePages,
} from '../resources/templates/demo_clothing_company/demo_clothing_company_structure';
import DemoPagesComponent from '../components/DemoPagesComponent';

const useStyles = makeStyles(theme => ({
  page: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  content: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
  },
  filters: {
    width: 300,
    flexDirection: 'column',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

const SidebarStyled = styled('div')`
    width: ${(props) => (props.open ? stylesConfig.drawerWidth : 80)}px;
    border-right: 1px solid ${(props) => props.borderColor};
`;

const ContentStyled = styled('div')`
    flex: 1;
    overflow: auto;
`;

function DashboardPage() {
  const auth = useAuth();

  const classes = useStyles();

  const theme = useTheme();
  const mediaQueryMD = useMediaQuery(theme.breakpoints.up('md'));

  const {
    analyticsMenuPages,
  } = useContext(MenuContext);

  const {tenant} = useContext(TenantContext);

  const [pages, setPages] = useState([]);

  const [isDemo, setIsDemo] = useState(false);

  async function listPages() {
    try {
      const items = await getAllPagesByTenant(
          auth?.user?.access_token,
          tenant?.slug,
      )

      setPages(items);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (tenant?.slug === 'demo') {
      setIsDemo(true);
      setPages([])
      return;
    } else {
      setIsDemo(false);
    }

    listPages().then();
  }, [tenant?.slug]);

  if (isNullOrBlank(tenant?.slug)) {
    return <LoadingComponent/>;
  }

  return (
      <div className={classes.page}>
        <div className={classes.content}>
          {/* MOBILE LEFT SIDEBAR IN PATH ANALYTICS */}
          {!mediaQueryMD ? (
              <DrawerComponent/>
          ) : null}

          {!mediaQueryMD ? (
              <DrawerFilterComponent/>
          ) : null}

          {mediaQueryMD ? (
              <SidebarStyled
                  open={analyticsMenuPages}
                  borderColor={theme.palette.divider}
              >

                <SidebarComponent
                    open={analyticsMenuPages}
                    loading={false}
                    structure={isDemo?formatFakeSidebarStructure():{pages}}
                />
              </SidebarStyled>
          ) : null}

          {/* CONTENT ROUTES WIDGETS */}
          <ContentStyled>

            {
              isDemo ? (
                  <DemoPagesComponent pages={getFakePages()} />
              ) : null
            }

            {
              pages.map((page, index) => (
                  <Route
                      key={`page-key-${index}`}
                      path={`/${ROUTE_PATH_ANALYTICS}/${page?.path}`}
                      render={() => <IFrameComponent
                          src={page?.metabase_url}
                          title={page?.name}
                      />
                      }
                  />
              ))
            }

            {
              !isDemo && pages?.length > 0 ? (
                    <>
                      <Route
                          to={`/${ROUTE_PATH_ANALYTICS}`}
                          exact
                          render={() => <Redirect to={`/${ROUTE_PATH_ANALYTICS}/${pages[0]?.path}`}/>}
                      />

                      <Route
                          render={() => <Redirect to={`/${ROUTE_PATH_ANALYTICS}/${pages[0]?.path}`}/>}
                      />
                    </>

                ):null
            }

          </ContentStyled>

          {/* FILTER MENU COMPONENT */}
          {mediaQueryMD ? (
              <FilterMenuComponent/>
          ) : null}
        </div>
      </div>
  );
}

export default DashboardPage;
