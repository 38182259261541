// export async function getFilterProvider(query, variables, typeReturn) {
//   const {
//     data: { Dimension },
//   } = await API.graphql({ query, variables });
//
//   return Dimension[typeReturn];
// }

export async function getFilterProvider(query, variables, typeReturn) {
  return {};
}
