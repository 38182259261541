function formatFakeTable({formatDataParams}) {
    let {headers, rows} = formatDataParams

    if (!headers || headers?.length <= 0) return {headers: [], rows: []}

    return {
        headers,
        rows
    }
}

export default formatFakeTable;
