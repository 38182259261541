import AccordionWidgetComponent from "./AccordionWidgetComponent/AccordionWidgetComponent";
import CardWidgetComponent from "./CardWidgetComponent/CardWidgetComponent";
import EmptyWidgetComponent from "./EmptyWidgetComponent/EmptyWidgetComponent";
import GraphWidgetComponent from "./GraphWidgetComponent/GraphWidgetComponent";
import TableWidgetComponent from "./TableWidgetComponent/TableWidgetComponent";
import ChartMapWidgetComponent from "./ChartMapWidgetComponent/ChartMapWidgetComponent";

function getWidgetByType(widgetType) {
    switch (widgetType) {
        case "table":
            return TableWidgetComponent;
        case "graph":
            return GraphWidgetComponent;
        case "accordion":
            return AccordionWidgetComponent;
        case "card":
            return CardWidgetComponent;
        case "map":
            return ChartMapWidgetComponent;
        default:
            return EmptyWidgetComponent;
    }
}

export default getWidgetByType;
