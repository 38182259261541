import React from "react";
import PropTypes from "prop-types";
import Dimensions from "react-dimensions";
import HighchartsComponent from "../../../HighchartsComponent";

class DimensionContent extends React.Component {
  render() {
    if (
      this.props.containerWidth === undefined ||
      this.props.containerHeight === undefined
    ) {
      return <div></div>;
    }

    return (
      <ChartMapWidgetComponent
        {...{
          ...this.props,
          width: this.props.containerWidth,
          height: this.props.containerHeight,
        }}
      />
    );
  }
}

function ChartMapWidgetComponent({ data: options, width, height }) {
  if (!options) {
    return <></>;
  }

  return (
    <HighchartsComponent
      containerProps={{ width, height }}
      options={options}
      constructorType={"mapChart"}
    />
  );
}

ChartMapWidgetComponent.propTypes = {
  options: PropTypes.object,
};

export default Dimensions()(DimensionContent);
