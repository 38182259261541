import { Divider, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FilterContext } from "../../../state/FilterContextProvider";

function FilterDateComponent({ state, name, setState }) {
  const { variables, setVariables } = useContext(FilterContext);

  return (
    <div
      style={{
        margin: 10,
        width: "calc(100%-20px)",
        height: "calc(100%-20px)",
        overflow: "auto",
      }}
    >
      <Typography variant="h5" component="h5" color="primary">
        {name}
      </Typography>
      <Divider />
      <br />
      <Calendar
        onChange={(i) => setVariables({ ...variables, [state]: i })}
        value={variables[state]}
      />
    </div>
  );
}

export default FilterDateComponent;
