import React, {useEffect, useRef, useState} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import {Avatar, IconButton} from '@material-ui/core';
import {useAuth} from 'react-oidc-context';

let ProfileButton = () => {
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = React.useRef(open);

  async function logout() {
    try {
      await auth.signoutRedirect();
      window.location.href = '/login';
    } catch (error) {
      console.error('LOGOUT ERROR: ', error);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
      <>
        <IconButton
            color="inherit"
            aria-label="Perfil"
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
        >
          <Avatar>
            {auth?.user?.profile?.name
                ? auth?.user?.profile?.name
                    .split(' ')
                    .map((i) => i[0])
                    .join('')
                    .toUpperCase()
                : 'UP'}
          </Avatar>
        </IconButton>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
        >
          {({TransitionProps, placement}) => (
              <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                    >
                      {/*<MenuItem>*/}
                      {/*  Meu Perfil*/}
                      {/*</MenuItem>*/}
                      {/*<MenuItem>*/}
                      {/*  Configurações*/}
                      {/*</MenuItem>*/}
                      <MenuItem onClick={logout}>
                        Sair
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
          )}
        </Popper>
      </>
  );
};

export default ProfileButton;
