import React from 'react';
import {makeStyles} from '@material-ui/core';
import HeaderComponent from './HeaderComponent';
import {Redirect, Route} from 'react-router-dom';
import {
  ROUTE_PATH_ANALYTICS,
  ROUTE_PATH_FILES,
  ROUTE_PATH_TEMPLATES,
  ROUTE_PATH_USERS,
} from '../defines';
import FilePage from '../pages/FilePage';
import TemplatePage from '../pages/TemplatePage';
import UserPage from '../pages/UserPage';
import DashboardPage from '../pages/DashboardPage';

const useStyles = makeStyles(() => ({
  all: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
  },
}));

function PrivateComponent() {
  const classes = useStyles();

  return (
      <div className={classes.all}>
        <div className={classes.root}>
          {/* HEADER IN ALL PAGES  */}
          <HeaderComponent/>

          <div className={classes.content}>
            {/* ROUTES */}
            <Route
                key="file-page-key"
                path={`/${ROUTE_PATH_FILES}`}
                render={() => <FilePage/>}
            />

            <Route
                key="template-page-key"
                path={`/${ROUTE_PATH_TEMPLATES}`}
                render={() => <TemplatePage/>}
            />

            <Route
                key="user-page-key"
                path={`/${ROUTE_PATH_USERS}`}
                render={() => <UserPage/>}
            />

            <Route
                key="analytics-page-key"
                path={`/${ROUTE_PATH_ANALYTICS}`}
                render={() => <DashboardPage/>}
            />

            <Route
                path="/"
                exact
                render={() => <Redirect to={`/${ROUTE_PATH_ANALYTICS}`}/>}
            />
          </div>
        </div>
      </div>
  );
}

export default PrivateComponent;
