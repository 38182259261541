import React from 'react';
import FilterContextProvider from './FilterContextProvider';
import AnalyticsContextProvider from './AnalyticsContextProvider';
import StructureContextProvider from './StructureContextProvider';
import MenuContextProvider from './MenuContextProvider';
import LoaderContextProvider from './LoadingContextProvider';
import TenantContextProvider from './TenantContextProvider';

export default function StateProvider({children}) {
  return (
      <TenantContextProvider>
        <FilterContextProvider>
          <AnalyticsContextProvider>
            <StructureContextProvider>
              <MenuContextProvider>
                <LoaderContextProvider>
                  {children}
                </LoaderContextProvider>
              </MenuContextProvider>
            </StructureContextProvider>
          </AnalyticsContextProvider>
        </FilterContextProvider>
      </TenantContextProvider>
  );
}
