import React, {useContext} from "react";
import {makeStyles, SwipeableDrawer, useTheme} from "@material-ui/core";
import FilterMenuComponent from "../FilterMenuComponent";
import TabFilterComponent from "../TabFilterComponent";
import {MenuContext} from "../../../state/MenuContextProvider";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "row",
    },
}));

function DrawerFilterComponent() {
    const theme = useTheme();
    const classes = useStyles();

    const {
        analyticsMenuFilters,
        setAnalyticsMenuFilters,
    } = useContext(MenuContext)

    return (
        <SwipeableDrawer
            anchor={"right"}
            open={analyticsMenuFilters}
            onClose={() => setAnalyticsMenuFilters(false)}
            onOpen={() => setAnalyticsMenuFilters(true)}
        >
            <div className={classes.content}>
                <FilterMenuComponent
                    openFilter={analyticsMenuFilters}
                    setOpenFilter={setAnalyticsMenuFilters}
                />
                <div
                    style={{
                        width: 250,
                        display: analyticsMenuFilters ? "flex" : "none",
                        flexDirection: "column",
                        borderLeft: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    <TabFilterComponent/>
                </div>
            </div>
        </SwipeableDrawer>
    );
}

export default DrawerFilterComponent;
