import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react";
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import highchartsMore from "highcharts/highcharts-more";

Highcharts.setOptions({
  colors: [
    "#b3c0a2",
    "#576b82",
    "#6b9080",

    "#2c4055",
    "#839073",
    "#566347",

    "#021a2c",
    "#fcbcb8",
    "#3f6254",
  ],
});

highchartsMap(Highcharts);
highchartsMore(Highcharts);

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

const HighchartsComponent = forwardRef(function HighchartsComponent(
  props,
  ref
) {
  const containerRef = useRef();
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.setSize(
        props.containerProps.width,
        props.containerProps.height
      );
    }
  }, [props.containerProps.width, props.containerProps.height]);

  useIsomorphicLayoutEffect(() => {
    function createChart() {
      const H = props.highcharts || Highcharts;
      const constructorType = props.constructorType || "chart";

      if (!H) {
        console.warn('The "highcharts" property was not passed.');
      } else if (!H[constructorType]) {
        console.warn(
          'The "constructorType" property is incorrect or some ' +
            "required module is not imported."
        );
      } else if (!props.options) {
        console.warn('The "options" property was not passed.');
      } else {
        // Create a chart
        chartRef.current = H[constructorType](
          containerRef.current,
          props.options,
          props.callback ? props.callback : undefined
        );
      }
    }

    if (!chartRef.current) {
      createChart();
    } else {
      if (props.allowChartUpdate !== false) {
        if (!props.immutable && chartRef.current) {
          chartRef.current.update(
            props.options,
            ...(props.updateArgs || [true, true])
          );
        } else {
          createChart();
        }
      }
    }
  });

  useIsomorphicLayoutEffect(() => {
    return () => {
      // Destroy chart only if unmounting.
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      get chart() {
        return chartRef.current;
      },
      container: containerRef,
    }),
    []
  );

  // Create container for the chart
  return <div {...props.containerProps} ref={containerRef} />;
});

export default memo(HighchartsComponent);
