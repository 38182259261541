import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import WidgetsComponent from "./Widgets/WidgetsComponent";

function WidgetComponent({ title, disableTitle, ...widget }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!disableTitle ? (
        <div className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
        </div>
      ) : null}
      <div className={classes.content}>
        <WidgetsComponent {...widget} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
  },
  header: {
    height: 30,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: 1,
  },
  title: {
    marginLeft: 10,
  },
}));

WidgetComponent.propTypes = {
  title: PropTypes.string.isRequired,
  widgetType: PropTypes.string.isRequired,
  disableTitle: PropTypes.bool,
};

export default WidgetComponent;
