import React, {useEffect, useState} from 'react';
import styled from '@material-ui/core/styles/styled';
import {useAuth} from "react-oidc-context";
import {getEmbedURLQuicksightProvider} from "../providers/quicksightProvider";

const IFrameStyledComponent = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
}));

const IFrameItemStyledComponent = styled('iframe')(({theme}) => ({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: 'none',
}));

export default function IFrameComponent({
                                            quicksight_account_id,
                                            quicksight_dashboard_id,
                                            src, title, ...props
                                        }) {
    const auth = useAuth();

    const [source, setSource] = useState("")

    useEffect(() => {
        if (quicksight_account_id !== "" && quicksight_dashboard_id !== "") {
            getEmbedURLQuicksightProvider(
                auth?.user?.access_token,
                quicksight_account_id,
                quicksight_dashboard_id
            ).then(setSource)
            return
        }

        setSource(src)
    }, [
        quicksight_account_id,
        quicksight_dashboard_id,
        src,
    ])

    return (
        <IFrameStyledComponent>
            {source ? (
                <IFrameItemStyledComponent
                    src={source}
                    title={title} {...props}
                />
            ) : 'Loading...'}
        </IFrameStyledComponent>
    );
}