import React, {useContext, useEffect, useState} from 'react';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  Select,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import logoWhite from '../assets/logo-white.svg';
import stylesConfig from '../config/styles.config';
import ProfileButton from './ProfileButtonComponent';
import {ROUTE_PATH_ANALYTICS} from '../defines';
import {Link as RouterLink} from 'react-router-dom';
import {MenuContext} from '../state/MenuContextProvider';
import {useAuth} from 'react-oidc-context';
import {getAllTenants} from '../providers/tenantProvider';
import MenuItem from '@material-ui/core/MenuItem';
import {TenantContext} from '../state/TenantContextProvider';

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  selectCompanyFormControl: {
    margin: 0,
    minWidth: 120,
  },
  selectCompanyInputLabel: {
    color: theme.palette.primary.contrastText,
  },
  selectCompanySelect: {
    color: theme.palette.secondary.main,
  },
}));

function HeaderComponent() {
  const auth = useAuth();

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const {setAnalyticsMenuPages} = useContext(MenuContext);

  const {tenant, setTenant} = useContext(TenantContext);

  const [tenants, setTenants] = useState([{
    id: 'demo',
    name: 'Demo',
    slug: 'demo'
  }]);

  // async function listTenants() {
  //   try {
  //     // const items = await getAllTenants(auth?.user?.access_token,
  //     //     {offset: 0, limit: 10})
  //
  //     if (tenants?.length > 0) {
  //       setTenants(tenants);
  //       setTenant(tenants[0]);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  function selectTenant(e) {
    e.preventDefault();
    setTenant(e.target.value);
  }

  // useEffect(() => {
    // if (auth.isLoading) {
    //   return;
    // }

    // if (auth.isAuthenticated) {
    //   listTenants().then();
    // }

    // setTenants(tenants[0])
  // }, []);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
      <AppBar
          position="relative"
          className={classes.appBar}
          elevation={0}
          color="primary"
      >
        <Toolbar>
          <div
              style={{
                minWidth: stylesConfig.drawerWidth - 24,
                display: 'flex',
                alignItems: 'center',
                flex: 1,
              }}
          >
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => setAnalyticsMenuPages(i => !i)}
            >
              <Menu/>
            </IconButton>
            <img
                style={{height: 30, width: 'auto'}}
                src={logoWhite}
                alt="logo"
            />
            <Box
                style={{marginLeft: '20px'}}
                sx={{
                  flexGrow: 1, display: {
                    // xs: 'none',
                    md: 'flex',
                  },
                }}>
              <Button
                  key="link-analytics-key"
                  // onClick={handleCloseNavMenu}
                  color="secondary"
                  component={RouterLink}
                  to={`/${ROUTE_PATH_ANALYTICS}`}
              >
                Analises
              </Button>
              {/*<Button*/}
              {/*    key="link-files-key"*/}
              {/*    // onClick={handleCloseNavMenu}*/}
              {/*    color="secondary"*/}
              {/*    component={RouterLink}*/}
              {/*    to={`/${ROUTE_PATH_FILES}`}*/}
              {/*>*/}
              {/*  Arquivos*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*    key="link-template-key"*/}
              {/*    // onClick={handleCloseNavMenu}*/}
              {/*    color="secondary"*/}
              {/*    component={RouterLink}*/}
              {/*    to={`/${ROUTE_PATH_TEMPLATES}`}*/}
              {/*>*/}
              {/*    Templates*/}
              {/*</Button>*/}
            </Box>
          </div>
          {matches ? (
              <>
                <div style={{flex: 1}}/>
                <FormControl
                    className={classes.selectCompanyFormControl}
                >
                  <InputLabel
                      id="header-company-select-label"
                      className={classes.selectCompanyInputLabel}
                  >
                    Selecione a Empresa
                  </InputLabel>
                  <Select
                      labelId="header-company-select-label"
                      id="header-company-select"
                      className={classes.selectCompanySelect}
                      value={tenant}
                      onChange={selectTenant}
                  >
                    {tenants.map((item, index) => (
                        <MenuItem key={index} value={item}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <ProfileButton/>
              </>
          ) : (
              <>
                {/*<IconButton*/}
                {/*    color="inherit"*/}
                {/*    aria-label="filter"*/}
                {/*    onClick={() => setAnalyticsMenuFilters(i => !i)}*/}
                {/*>*/}
                {/*  <FilterListIcon/>*/}
                {/*</IconButton>*/}
              </>
          )}
        </Toolbar>
      </AppBar>
  );
}

export default HeaderComponent;
