import React from "react";
import PropTypes from "prop-types";
import HighchartsComponent from "../../../HighchartsComponent";
import Dimensions from "react-dimensions";

class DimensionContent extends React.Component {
  render() {
    if (
      this.props.containerWidth === undefined ||
      this.props.containerHeight === undefined
    ) {
      return <div></div>;
    }

    return (
      <GraphWidgetComponent
        {...{
          ...this.props,
          width: this.props.containerWidth,
          height: this.props.containerHeight,
        }}
      />
    );
  }
}

function GraphWidgetComponent({ data: options, width, height }) {
  if (!options) {
    return <></>;
  }

  return (
    <HighchartsComponent containerProps={{ width, height }} options={options} />
  );
}

GraphWidgetComponent.propTypes = {
  options: PropTypes.object,
};

export default Dimensions()(DimensionContent);
