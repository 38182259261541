import React, {createContext, useState} from 'react';

export const MenuContext = createContext({});

function MenuContextProvider({children}) {
    const [analyticsMenuPages, setAnalyticsMenuPages] = useState(true);
    const [analyticsMenuFilters, setAnalyticsMenuFilters] = useState(false);

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <MenuContext.Provider
            value={{
                analyticsMenuPages,
                setAnalyticsMenuPages,
                analyticsMenuFilters,
                setAnalyticsMenuFilters,
            }}
        >
            {children}
        </MenuContext.Provider>
    )
}

export default MenuContextProvider;
