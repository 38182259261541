import React from "react";
import { SvgIcon } from "@material-ui/core";

const ProductionIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374 374">
        <path d="M53 293H97V329H53z"></path>
        <path d="M340.519 82L279.138 82 267.897 186 351.759 186z"></path>
        <path d="M336.342 43L283.314 43 280.864 66 338.792 66z"></path>
        <path d="M165 293H209V329H165z"></path>
        <path d="M331.729 0L287.928 0 285.041 27 334.615 27z"></path>
        <path d="M239.641 202c-4.418 0-7.641-3.833-7.641-8.251v-30.728l-74.479 36.991a8.202 8.202 0 01-7.88-.363 7.864 7.864 0 01-3.641-6.8v-29.123l-66.643 37.01a8.477 8.477 0 01-8.2-.09 8.201 8.201 0 01-4.158-6.9v-30.02L5 198.448V374h364V202H239.641zM113 337a8 8 0 01-8 8H45a8 8 0 01-8-8v-52a8 8 0 018-8h60a8 8 0 018 8v52zm-7.886-78H44.825a8 8 0 010-16h60.289a8 8 0 010 16zM225 337a8 8 0 01-8 8h-60a8 8 0 01-8-8v-52a8 8 0 018-8h60a8 8 0 018 8v52zm-7.855-78h-60.29a8 8 0 010-16h60.29a8 8 0 010 16zM337 337a8 8 0 01-8 8h-60a8 8 0 01-8-8v-52a8 8 0 018-8h60a8 8 0 018 8v52zm-7.375-78h-60.29a8 8 0 010-16h60.29a8 8 0 010 16z"></path>
        <path d="M277 293H322V329H277z"></path>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(ProductionIcon);
