export default `
  query(
    $dateStart: DateTime!
    $dateEnd: DateTime!
    $storeIDs: [Int]
    $productIDs: [Int]
  ){
    Sale{
      Week(
        dateStart: $dateStart
        dateEnd: $dateEnd
        storeIDs: $storeIDs
        productIDs: $productIDs
      ){
        Date
        Quantity
      }
    }
    Prediction{
      Sale(
        dateStart: $dateStart
        dateEnd: $dateEnd
        storeIDs: $storeIDs
        productIDs: $productIDs
      ){
        Date
        Quantity
      }
    }
  }
`;
