import React from "react";
import { SvgIcon } from "@material-ui/core";

const SupplyIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M193.883 334.792c-15.895 0-28.825 12.931-28.825 28.825s12.931 28.825 28.825 28.825 28.825-12.931 28.825-28.825-12.931-28.825-28.825-28.825z"></path>
        <path d="M116.488 119.558v229.059h20.515c6.649-25.193 29.627-43.825 56.88-43.825s50.23 18.632 56.88 43.825h94.785V119.558h-229.06zM443.801 211.74h-68.252v136.877h11.346c6.649-25.193 29.627-43.825 56.88-43.825s50.23 18.632 56.88 43.825H512V286.02l-68.199-74.28z"></path>
        <path d="M443.774 334.792c-15.894 0-28.825 12.931-28.825 28.825s12.931 28.825 28.825 28.825 28.825-12.931 28.825-28.825-12.931-28.825-28.825-28.825z"></path>
        <path d="M27.689 119.56H86.489V149.56H27.689z"></path>
        <path d="M27.689 239.56H86.489V269.56H27.689z"></path>
        <path d="M0 179.56H86.49V209.56H0z"></path>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(SupplyIcon);
