import React, {createContext} from "react";

export const LoaderContext = createContext({});

function LoaderContextProvider({children}) {
    const [loading, setLoading] = React.useState(false);

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <LoaderContext.Provider
            value={{
                loading,
                setLoading,
            }}
        >
            {children}
        </LoaderContext.Provider>
    )
}

export default LoaderContextProvider;
