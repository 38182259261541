import React from 'react';
import LoadingComponent from './components/LoadingComponent';
import PrivateComponent from './components/PrivateComponent';
import StateProvider from './state';
import LoginOIDCPage from './pages/LoginOIDCPage';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import LoginCallbackPage from './pages/LoginCallbackPage';
import {useAuth} from 'react-oidc-context';

function App() {
  // const auth = useAuth();
    const auth = {
        isAuthenticated: true
    }

  if (auth.isLoading) {
    return <LoadingComponent/>;
  }

  return (
      <BrowserRouter>
        <Switch>
          <Route
              path="/callback"
              exact
              render={() => <LoginCallbackPage/>}
          />
          {auth.isAuthenticated ? (
              <StateProvider>
                <PrivateComponent/>
              </StateProvider>
          ) : (
              <>
                <Route
                    path="/login"
                    exact
                    render={() => <LoginOIDCPage/>}
                />
                <Route
                    path="/"
                    exact
                    render={() => <Redirect to={`/login`}/>}
                />
              </>
          )}
          <Route render={() => <Redirect to="/"/>}/>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
