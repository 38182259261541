import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import WidgetComponent from "../../WidgetComponent";
import {getFormatDataFunction} from "../../../../formatdata";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

function AccordionWidgetComponent({labels}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {labels.map((label, iLabel) => {

                const {widget} = label
                const [formatData, ...formatDataParam] = R.split('-', widget.formatData);
                const formatDataFunc = getFormatDataFunction(formatData)
                let formatDataParamObj = {}
                if (formatDataParam?.length > 0) {
                    formatDataParamObj = JSON.parse(R.join('-', formatDataParam));
                }

                return (
                    <Accordion
                        key={`accordion-${label.name}-${iLabel}-${(new Date).toISOString()}`}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography className={classes.heading}>{label.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <WidgetComponent
                                {...{
                                    ...widget,
                                    formatData: formatDataFunc,
                                    formatDataParams: formatDataParamObj
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

AccordionWidgetComponent.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.object)
};

export default AccordionWidgetComponent;
