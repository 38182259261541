import React from "react";
import { SvgIcon } from "@material-ui/core";

const StockIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M502.39 69.717l-47.785-18.976c-15.899-6.314-33.305-6.058-49.011.719-15.707 6.778-27.834 19.267-34.147 35.165l-94.353 237.593a87.046 87.046 0 0114.651 4.362 87.805 87.805 0 0113.654 6.876l94.352-237.591c3.311-8.338 9.672-14.889 17.909-18.443 8.237-3.555 17.365-3.689 25.705-.378L491.15 98.02c7.816 3.103 16.667-.716 19.771-8.532 3.104-7.815-.716-16.667-8.531-19.771zM280.504 356.883c-28.927-11.487-61.806 2.7-73.292 31.627-5.564 14.013-5.339 29.353.634 43.196 5.973 13.843 16.98 24.531 30.992 30.096 6.744 2.679 13.799 4.016 20.844 4.016 7.592.001 15.173-1.551 22.353-4.65 13.842-5.973 24.531-16.98 30.095-30.992 11.488-28.927-2.699-61.806-31.626-73.293zM282.108 215.743a15.228 15.228 0 00-8.36-8.119L110.461 142.78c-7.815-3.103-16.667.716-19.771 8.532L30.818 302.076l-9.967-3.958c-7.816-3.103-16.667.716-19.771 8.532-3.105 7.815.716 16.667 8.531 19.771l22.514 8.941c.507.271 1.025.523 1.565.737l140.83 55.926a87.988 87.988 0 014.389-14.755c6.547-16.489 17.808-30.395 32.565-40.214 9.92-6.6 20.947-11.05 32.486-13.174l38.317-96.488a15.215 15.215 0 00-.169-11.651zm-83.651 33.75c-2.371 5.972-8.1 9.611-14.156 9.611-1.87 0-3.771-.347-5.616-1.079l-33.64-13.359c-7.815-3.103-11.635-11.956-8.532-19.771 3.104-7.816 11.956-11.636 19.771-8.532l33.639 13.359c7.817 3.104 11.637 11.956 8.534 19.771z"></path>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(StockIcon);
