import React, { useState } from "react";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { ArrowDropDown, ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  item: {
    paddingLeft: ({ marginLeft, open }) => (open ? marginLeft : null),
    "&:hover": {
      cursor: "pointer",
    },
  },
  itemLabel: {
    display: "flex",
    alignItems: "center",
    height: 40,
    width: (props) => (props.open ? "100%" : 40),
    justifyContent: (props) => (props.open ? null : "center"),
    borderRadius: (props) => (props.open ? null : "50%"),
    "&:hover": {
      backgroundColor: theme.palette.divider,
    },
    "&:link, &:visited": {
      ccursor: "pointer",
      textDecoration: "none",
      color: "inherit",
    },
  },
  itemIcon: {
    margin: ({ open }) => (open ? "5px 8px 5px 0" : "5px 0"),
  },
}));

function ParentSidebarComponent({
  open,
  name,
  icon: IconComponent,
  marginLeft,
  children,
}) {
  const [openItem, setOpenItem] = useState(false);

  const classes = useStyles({ open, marginLeft });

  return (
    <div className={classes.item}>
      <div className={classes.itemLabel} onClick={() => setOpenItem(!openItem)}>
        {open ? openItem ? <ArrowDropDown /> : <ArrowRight /> : null}
        <Tooltip title={name}>
          <IconComponent className={classes.itemIcon} />
        </Tooltip>
        <Typography style={{ display: open ? null : "none" }}>
          {name}
        </Typography>
      </div>
      <div style={{ display: openItem ? null : "none" }}>{children}</div>
    </div>
  );
}

export default ParentSidebarComponent;
