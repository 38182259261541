import React, {useContext} from "react";
import {makeStyles, Tab, Tabs, Tooltip} from "@material-ui/core";

import {FilterContext} from "../../state/FilterContextProvider";
import {MenuContext} from "../../state/MenuContextProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        borderLeft: `1px solid ${theme.palette.divider}`,
    },
    tab: {
        minWidth: 10,
    },
}));

function FilterMenuComponent() {
    const classes = useStyles();
    const {filters, filterItem, setFilterItem} = useContext(FilterContext)
    const {analyticsMenuFilters, setAnalyticsMenuFilters} = useContext(MenuContext)

    const handleChange = (value) => {
        if (value === filterItem) {
            setAnalyticsMenuFilters(i => !i);
            return;
        }

        if (!analyticsMenuFilters) {
            setAnalyticsMenuFilters(true);
        }

        setFilterItem(value);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={filterItem}
                indicatorColor="secondary"
                onChange={(event, value) => handleChange(value)}
                aria-label="menu filter"
            >
                {filters.map(({name, value, icon: Icon}) => {
                    return (
                        <TabTooltip
                            key={value}
                            value={value}
                            name={name}
                            className={classes.tab}
                            icon={<Icon/>}
                            aria-label={value}
                        />
                    );
                })}
            </Tabs>
        </div>
    );
}

export default FilterMenuComponent;

function TabTooltip({name, ...props}) {
    return (
        <Tooltip title={name} placement="left">
            <Tab {...props} />
        </Tooltip>
    );
}
