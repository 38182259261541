import React from 'react';
import backgroundImage from '../assets/background_ia_3.jpeg';
import logoBottom from '../assets/logo-with-text-white-horizontal.png';
import {Button, CircularProgress, useTheme} from '@material-ui/core';
import logo from '../assets/logo-with-text.png';
import {useAuth} from 'react-oidc-context';

function LoginOIDCPage() {
  const theme = useTheme();
  const auth = useAuth();

  console.log('auth', auth)

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
      <>
        <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
        >
          <div
              style={{
                flex: 1,
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'left center',
              }}
          >
            <div
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: `${theme.palette.primary.main}aa`,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
            >
              <div
                  style={{
                    height: 30,
                    maxWidth: 210,
                    width: '100%',
                    marginBottom: 10,
                    backgroundImage: `url(${logoBottom})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'right top',
                  }}
              />
            </div>
          </div>
          <div
              style={{
                width: '100%',
                maxWidth: 400,
                backgroundColor: 'white',
                height: '100%',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 20px',
              }}
          >
            {auth.isLoading ? (
                <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      zIndex: 99999,
                    }}
                >
                  <CircularProgress color="secondary" />
                </div>
            ) : null}
            <div
                style={{
                  width: '100%',
                }}
            >
              <div
                  style={{
                    backgroundImage: `url(${logo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    height: 160,
                    width: '100%',
                    marginBottom: 40,
                  }}
              />

              <Button
                  variant="contained"
                  onClick={() => auth.signinRedirect()}
                  color="secondary"
                  fullWidth
              >
                Login com o SSO
              </Button>
            </div>
          </div>
        </div>
      </>
  );
}

export default LoginOIDCPage;
