import {ROUTE_PATH_ANALYTICS} from '../defines';
import {Route} from 'react-router-dom';
import GenericPage from '../pages/GenericPage';
import React from 'react';
import IFrameComponent from './IFrameComponente';
import {useAuth} from "react-oidc-context";

function DemoPagesComponent({pages}) {
    return (
        <>
            {
                pages.map((p, pi) => {
                    const path = `/${ROUTE_PATH_ANALYTICS}/${[
                        ...p.parentPath,
                        p.path].join('/')}`;

                    return (
                        <Route key={pi} path={path}
                               render={() => {
                                   if (p?.page_type === 'iframe') {
                                       return (
                                           <IFrameComponent
                                               quicksight_account_id={p?.quicksight_account_id}
                                               quicksight_dashboard_id={p?.quicksight_dashboard_id}
                                               src={p?.metabase_url}
                                               title={p?.name}
                                           />
                                       );
                                   }

                                   return (
                                       <GenericPage {...p} />
                                   );

                               }}
                        />
                    );
                })
            }
        </>
    );
}

export default DemoPagesComponent;
