import { makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

function CardWidgetComponent({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQueryMD = useMediaQuery(theme.breakpoints.up("md"));

  if (!data) {
    return <></>;
  }

  const { title, value, subtitle } = data;

  return (
    <div className={classes.root}>
      <Typography color="primary">{title}</Typography>
      { mediaQueryMD ?
        <Typography variant="h3" color="secondary">
          {value}
        </Typography>
        :
        <Typography variant="h4" color="secondary">
          {value}
        </Typography>
      }
      {subtitle ? (
        <Typography variant="h6" color="primary">
          {subtitle}
        </Typography>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

export default CardWidgetComponent;
