export const GRAPH_API = process.env?.REACT_APP_API ?
    process.env?.REACT_APP_API :
    'https://cc5c-191-6-12-74.ngrok-free.app/v1';

export const USER_TENANTS = `${GRAPH_API}/users/tenants`;

export const USER_TENANT_PAGES = `${GRAPH_API}/users/pages`;

export const FILE_PROCESSOR_API = process.env?.REACT_APP_FILE_PROCESSOR_API ?
    process.env?.REACT_APP_FILE_PROCESSOR_API :
    // 'http://localhost:8080/v1/users'
    'https://cc5c-191-6-12-74.ngrok-free.app/v1/users'

export const QUICKSIGHT_EMBED_URL = `${FILE_PROCESSOR_API}/quicksight/embed-url`
