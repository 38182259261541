import React from "react";
import { SvgIcon } from "@material-ui/core";

const CommercialIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.872 29.872">
        <path d="M26.761 15.56l-9.9-9.9c-.829-.83-2.17-1.3-3.335-1.171l-3.712.413.548-3.758a1.001 1.001 0 00-1.98-.289L7.759 5.13l-1.794.199c-1.386.155-2.714 1.481-2.87 2.872l-.839 7.558c-.13 1.166.34 2.507 1.17 3.337l9.899 9.899a3.004 3.004 0 004.242 0l9.192-9.191a3.004 3.004 0 00.002-4.244zM7.878 11.245a.997.997 0 001.134-.845l.248-1.703c.06.047.122.089.177.145a2 2 0 11-2.154-.443l-.25 1.713a1 1 0 00.845 1.133zm8.437 10.162l-1.012 1.011-2.079-9.803 1.019-1.02 2.072 9.812zm-.341-4.811c-.016-.565.206-1.077.665-1.535.441-.442.915-.656 1.421-.643.505.015.995.259 1.472.733.488.489.74 1.015.758 1.578.017.562-.203 1.073-.658 1.529-.423.422-.897.629-1.424.618-.525-.01-1.017-.24-1.47-.693-.494-.494-.75-1.023-.764-1.587zm-2.393.826c.015.562-.207 1.069-.662 1.524-.423.423-.897.629-1.424.62-.526-.01-1.016-.241-1.469-.694-.494-.494-.749-1.023-.765-1.589-.015-.564.207-1.076.665-1.535.439-.438.914-.65 1.424-.636.51.015 1.002.26 1.477.735.489.489.74 1.014.754 1.575zm12.473 1.673l-9.192 9.191c-.779.78-2.048.78-2.828 0l-9.899-9.898c-.606-.607-.979-1.666-.883-2.52l.838-7.556c.054-.471.292-.939.672-1.319.38-.38.849-.618 1.316-.67l1.533-.17-.149 1.023-1.273.14c-.547.061-1.044.558-1.104 1.105l-.839 7.559c-.062.547.207 1.312.596 1.701l9.899 9.898a1 1 0 001.413 0l9.192-9.191a1 1 0 000-1.414l-9.898-9.899c-.389-.389-1.154-.658-1.701-.596l-4.229.468.148-1.021 3.972-.441c.852-.095 1.911.276 2.518.883l9.899 9.899a2.004 2.004 0 01-.001 2.828z"></path>
        <path d="M18.951 17.479c.393-.393.312-.864-.24-1.417-.257-.257-.509-.403-.754-.439a.697.697 0 00-.626.203c-.4.4-.32.881.239 1.442.531.532.991.601 1.381.211zM10.631 16.502c-.403.403-.325.886.236 1.446.53.53.987.604 1.371.22.392-.392.312-.864-.24-1.417-.539-.539-.994-.622-1.367-.249z"></path>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(CommercialIcon);
