import moment from "moment";
import { TYPES } from "../components/FilterComponents/TabFilterComponent";
import icons from "../icons";
import queries from "../queries";
import currencyFormatter from "../util/currencyFormatter";
import formatGraphPrediction from "./formatGraphPrediction";
import formatCardPredictionAccuracy from "./formatCardPredictionAccuracy";
import formatTablePredictionProduct from "./formatTablePredictionProduct";
import formatMapGraphPrediction from "./formatMapGraphPrediction";
import formatGraphPredictionMonth from "./formatGraphPredictionMonth";
import fake from "./fake";

const formats = {
  ...fake,
  formatProfileFilters,
  formatProfileStructure,
  formatDataSaleGrossAmount,
  formatDataSaleNetAmount,
  formatDataSaleQuantity,
  formatDataSaleBestSellingStore,
  formatDataSaleBestSellingGroup,
  formatDataSaleBestSellingProduct,
  formatDataSaleBestSellingSize,
  formatDataSaleBestSellingColor,
  formatDataPredictionGrid,
  formatGraphPrediction,
  formatCardPredictionAccuracy,
  formatTablePredictionProduct,
  formatMapGraphPrediction,
  formatGraphPredictionMonth,
};

export default formats;

export function formatProfileFilters(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      type: TYPES[filter.type],
      icon: icons[filter.icon],
    };
  });
}

function formatProfileStructureRecursive(pages) {
  return pages.map((pageOld) => {
    const page = {
      ...pageOld,
      icon: icons[pageOld.icon],
    };

    if (page.pages && page.pages.length > 0) {
      return {
        ...page,
        pages: formatProfileStructureRecursive(page.pages),
      };
    }

    return {
      ...page,
      rows: page.rows.map((row) => {
        return {
          ...row,
          widgets: row.widgets.map((widget) => {
            return {
              ...widget,
              query: queries[widget.query],
              formatData: getFormatDataFunction(widget.formatData),
            };
          }),
        };
      }),
    };
  });
}

export function getFormatDataFunction(formatData) {
    return formats[formatData];
}

export function formatProfileStructure(data) {
  return {
    ...data,
    pages: formatProfileStructureRecursive(data.pages),
  };
}

export function formatDataPredictionGrid({ Prediction: { Grid } }) {
  return {
    headers: ["Mês", "Loja", "Grupo", "Cor", "Tamanho", "Quantidade"],
    rows: Grid.map((grid) => [
      moment.utc(grid.Date).format("MMMM").toUpperCase(),
      grid.Store,
      grid.Group,
      grid.Color,
      grid.Size,
      grid.Quantity.toFixed(0),
    ]),
  };
}

export function formatDataSaleBestSellingSize({
  Sale: {
    BestSellingSize: { Name, NetAmount },
  },
}) {
  return {
    title: "Tamanho mais vendido",
    value: Name,
    subtitle: currencyFormatter.format(NetAmount),
  };
}

export function formatDataSaleBestSellingColor({
  Sale: {
    BestSellingColor: { Name, NetAmount },
  },
}) {
  return {
    title: "Cor mais vendida",
    value: Name,
    subtitle: currencyFormatter.format(NetAmount),
  };
}

export function formatDataSaleBestSellingProduct({
  Sale: {
    BestSellingProduct: { Name, NetAmount },
  },
}) {
  return {
    title: "Produto que mais vendeu",
    value: Name,
    subtitle: currencyFormatter.format(NetAmount),
  };
}

export function formatDataSaleGrossAmount({ Sale: { GrossAmount } }) {
  return {
    title: "Valor total bruto de vendas",
    value: currencyFormatter.format(GrossAmount),
  };
}

export function formatDataSaleNetAmount({ Sale: { NetAmount } }) {
  return {
    title: "Valor total líquido de vendas",
    value: currencyFormatter.format(NetAmount),
  };
}

export function formatDataSaleQuantity({ Sale: { Quantity } }) {
  return {
    title: "Quantidade total de produtos vendidos",
    value: Quantity,
  };
}

export function formatDataSaleBestSellingStore({
  Sale: {
    BestSellingStore: { Name, NetAmount },
  },
}) {
  return {
    title: "Loja que mais vendeu",
    value: Name,
    subtitle: currencyFormatter.format(NetAmount),
  };
}

export function formatDataSaleBestSellingGroup({
  Sale: {
    BestSellingGroup: { Name, NetAmount },
  },
}) {
  return {
    title: "Grupo que mais vendeu",
    value: Name,
    subtitle: currencyFormatter.format(NetAmount),
  };
}
