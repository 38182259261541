import Highcharts from "highcharts";
import * as R from "ramda";

export default () => ({
  title: {
    text: "Simulador de preço",
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  yAxis: [
    {
      title: {
        text: "QTD",
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: "{value}",
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
    },
    {
      // Secondary yAxis
      min: 0,
      max: R.reduce(R.max, 0, R.flatten(data.series.map((i) => i.data))),
      title: {
        text: "Lucro",
        style: {
          color: Highcharts.getOptions().colors[7],
        },
      },
      labels: {
        format: "R$ {value}",
        style: {
          color: Highcharts.getOptions().colors[7],
        },
      },
      opposite: true,
    },
    {
      // Secondary yAxis
      min: 0,
      max: R.reduce(R.max, 0, R.flatten(data.series.map((i) => i.data))),
      title: {
        text: "Faturamento",
        style: {
          color: Highcharts.getOptions().colors[3],
        },
      },
      labels: {
        format: "R$ {value}",
        style: {
          color: Highcharts.getOptions().colors[3],
        },
      },
      opposite: true,
    },
  ],
  xAxis: {
    title: {
      text: "Preço de Venda",
    },
    categories: data.categories.map((i) => `R$ ${i}`),
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
      },
      color: Highcharts.getOptions().colors[0],
      enableMouseTracking: false,
    },
    line: {
      dataLabels: {
        enabled: true,
        format: "R$ {point.y:,.0f}",
      },
      enableMouseTracking: false,
    },
  },
  legend: {
    enabled: false,
  },
  series: data.series.map((i, index) => {
    switch (index) {
      case 0:
        return {
          type: "column",
          name: "",
          data: i.data,
          color: Highcharts.getOptions().colors[0],
        };
      case 1:
        return {
          yAxis: index,
          name: "",
          data: i.data,
          color: Highcharts.getOptions().colors[3],
        };
      case 2:
        return {
          yAxis: index,
          name: "",
          data: i.data,
          color: Highcharts.getOptions().colors[7],
        };
      default:
        return {
          yAxis: index,
          name: "",
          data: i.data,
          color: Highcharts.getOptions().colors[7],
        };
    }
  }),
});

const data = {
  categories: ["99", "110", "120", "130", "140"],
  series: [
    { data: [1170, 950, 850, 700, 500] },
    { data: [115830, 104500, 102000, 91000, 70000] },
    { data: [31590, 36100, 40800, 40600, 34000] },
  ],
};
