import React from "react";
import { SvgIcon } from "@material-ui/core";

const PromotionIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.005 512.005">
        <path d="M464.001.005h-224c-26.47 0-48 21.53-48 48v72.64l-8.82 4.19-12.81-5.85c21.812-57.911-21.69-120.43-84.49-118.96C38.783 1.142.591 39.716.011 86.865c-.48 37.44 22.73 71.01 57.84 83.82l14.37 6.82-14.37 6.82c-35.11 12.81-58.32 46.38-57.84 83.81.579 47.115 38.719 85.732 85.87 86.85 64.202.892 105.992-61.873 84.49-118.96l12.81-5.85 8.82 4.19v229.64c0 26.47 21.53 48 48 48h224c26.47 0 48-21.53 48-48v-416c0-26.47-21.53-48-48-48zm-211.19 198.39c7.801 10.454.457 25.61-12.81 25.61-7.68 0-14.09-5.41-15.65-12.62zm43.19 65.61c17.64 0 32 14.35 32 32s-14.36 32-32 32-32-14.35-32-32 14.36-32 32-32zm-16-56c0-8.84 7.16-16 16-16s16 7.16 16 16-7.16 16-16 16-16-7.16-16-16zm3.37 206.18l112-144c5.43-6.98 15.48-8.23 22.45-2.81 6.98 5.43 8.23 15.48 2.81 22.45l-112 144c-5.396 6.955-15.452 8.25-22.45 2.81-6.98-5.43-8.23-15.48-2.81-22.45zm52.63-206.18c0-8.84 7.16-16 16-16s16 7.16 16 16-7.16 16-16 16-16-7.16-16-16zm56 0c0-8.84 7.16-16 16-16s16 7.16 16 16-7.16 16-16 16-16-7.16-16-16zm16 168c17.64 0 32 14.35 32 32s-14.36 32-32 32-32-14.35-32-32 14.36-32 32-32zm-338.66-235.19c-22.64-8.01-37.64-29.52-37.33-53.54.373-30.018 25.404-55.27 56-55.27 44.55 0 70.695 49.273 47.22 86.08-5.216 8.169-2.012 19.107 6.85 23.16l3.32 1.51-35.88 17.04c-42.514-20.179-39.162-18.627-40.18-18.98zm65.89 96.11c24.068 37.738-3.86 87.118-48.59 86.06-29.75-.7-54.26-25.49-54.63-55.26-.31-24.01 14.69-45.52 37.33-53.53 1.093-.378-13.609 6.556 246.89-117.11 26.396-12.524 56.675-12.398 82.57-.51l-256.72 117.2c-8.838 4.021-12.079 14.96-6.85 23.15zm328.77-12.92c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16z"></path>
        <circle cx="88.001" cy="267.005" r="24"></circle>
        <circle cx="88.001" cy="88.005" r="24"></circle>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(PromotionIcon);
