import React from "react";
import { SvgIcon } from "@material-ui/core";

const SaleIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <g data-name="sign · ecommerce · shopping · tag · sale">
          <path d="M23.238 33.69l3.182 5.304 2.432-2.432-5.304-3.182zM50 13a1 1 0 011-1 5.621 5.621 0 011.312.176 2 2 0 10-.22 2.561 2.028 2.028 0 00.329-.451A3.8 3.8 0 0051 14a1 1 0 01-1-1z"></path>
          <path d="M56.621 15.137L57.4 10.1a3 3 0 00-2.954-3.457 3.023 3.023 0 00-.453.034l-15.4 2.3a7.048 7.048 0 00-3.917 1.973L4.716 40.9a3 3 0 000 4.243l14.142 14.141a3.071 3.071 0 004.243 0L40.485 41.9C41.855 51.325 45.986 58 51 58c6.168 0 11-10.1 11-23 0-8.572-2.136-15.906-5.379-19.863zm-21.328 3.671l3.536-3.536a1 1 0 111.414 1.414l-2.829 2.829 2.829 2.828 1.414-1.414a1 1 0 011.414 1.414l-1.414 1.414 2.828 2.829 2.829-2.829a1 1 0 111.414 1.414l-3.536 3.536a1 1 0 01-1.414 0l-8.485-8.485a1 1 0 010-1.414zM24.515 49.385l-2.829 2.828a1 1 0 01-1.414-1.413l2.828-2.829a1 1 0 000-1.415l-1.414-1.414a1 1 0 00-1.415 0l-1.414 1.414a3 3 0 01-4.243 0L13.2 45.142a3 3 0 010-4.243l2.828-2.828a1 1 0 011.414 1.414l-2.828 2.828a1 1 0 000 1.415l1.414 1.414a1 1 0 001.415 0l1.414-1.414a3 3 0 014.243 0l1.414 1.414a3 3 0 010 4.243zM34.857 39a1 1 0 01-1.372.343l-2.866-1.72-3.138 3.138 1.72 2.866a1 1 0 01-1.715 1.03L21.122 34.05a1 1 0 01.15-1.221l1.415-1.415a1 1 0 011.221-.15l10.607 6.364A1 1 0 0134.857 39zm2.971-2.929a1 1 0 01-1.414 0l-8.485-8.485a1 1 0 111.414-1.414l7.778 7.778 3.536-3.536a1 1 0 011.414 1.414zM51 56c-4.128 0-7.7-6.893-8.709-15.906l10.777-10.778a6.955 6.955 0 001.97-3.885l1.142-7.424a11.167 11.167 0 00-2.08-2.629 3.995 3.995 0 11-6.246-4.884 4.1 4.1 0 015.657 0 3.971 3.971 0 011.168 2.787 10.3 10.3 0 011.947 1.859l-.437 2.841C58.473 21.832 60 28.082 60 35c0 11.383-4.122 21-9 21z"></path>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(SaleIcon);
