import React from "react";
import { SvgIcon } from "@material-ui/core";

const RelocationIcon = (props, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M140 422h30v90h-30z"></path>
        <path d="M165.09 235.901c-5.721-5.201-14.459-5.201-20.18 0l-140 127.273c-6.13 5.573-6.582 15.06-1.009 21.189 5.572 6.129 15.059 6.58 21.189 1.009l4.91-4.464V497c0 8.284 6.716 15 15 15h65V407c0-8.284 6.716-15 15-15h60c8.284 0 15 6.716 15 15v105h65c8.284 0 15-6.716 15-15V380.908l4.91 4.464a14.942 14.942 0 0010.085 3.901c4.079 0 8.144-1.654 11.104-4.91 5.573-6.13 5.121-15.617-1.009-21.189zM170 362h-30c-8.284 0-15-6.716-15-15s6.716-15 15-15h30c8.284 0 15 6.716 15 15s-6.716 15-15 15zM507.607 356.394l-40-40c-5.857-5.858-15.355-5.858-21.213 0l-40 40c-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0L442 363.213V457c0 13.785-11.215 25-25 25h-90c-8.284 0-15 6.716-15 15s6.716 15 15 15h90c30.327 0 55-24.673 55-55v-93.787l14.394 14.393c2.928 2.93 6.767 4.394 10.606 4.394s7.678-1.464 10.606-4.394c5.858-5.857 5.858-15.354.001-21.212zM44.394 245.607C47.322 248.536 51.161 250 55 250s7.678-1.464 10.606-4.394l40-40c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0L70 198.787V55c0-13.785 11.215-25 25-25h160c8.284 0 15-6.716 15-15s-6.716-15-15-15H95C64.673 0 40 24.673 40 55v143.787l-14.394-14.393c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213zM205.901 152.363c5.572 6.129 15.059 6.581 21.189 1.009l4.91-4.464V265c0 8.284 6.716 15 15 15h65V175c0-8.284 6.716-15 15-15h60c8.284 0 15 6.716 15 15v105h65c8.284 0 15-6.716 15-15V148.908l4.91 4.464a14.942 14.942 0 0010.085 3.901c4.079 0 8.144-1.654 11.104-4.91 5.573-6.13 5.121-15.617-1.009-21.189l-140-127.273c-5.721-5.201-14.459-5.201-20.18 0l-140 127.273c-6.13 5.573-6.582 15.059-1.009 21.189zM342 100h30c8.284 0 15 6.716 15 15s-6.716 15-15 15h-30c-8.284 0-15-6.716-15-15s6.716-15 15-15z"></path>
        <path d="M342 190h30v90h-30z"></path>
      </svg>
    </SvgIcon>
  );
};

export default React.forwardRef(RelocationIcon);
