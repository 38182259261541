import axios from 'axios';
import {useQuery} from 'react-query';

const endpoint = 'https://api.spacex.land/graphql/';
const FILMS_QUERY = `
  {
    launchesPast(limit: 10) {
      id
      mission_name
    }
  }
`;

export async function getGenericProvider(query, variables = {}) {
  if (!query) {
    return {query, variables};
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {data, isLoading, error} = useQuery('launches', () => {
    return axios({
      url: endpoint,
      method: 'POST',
      data: {
        query: FILMS_QUERY,
      },
    }).then(response => response.data.data);
  });

  // const {data} = await API.graphql({query, variables});
  return data;
}
