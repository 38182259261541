export const ADMIN_QUERIES_API_NAME = 'AdminQueries';
export const ADMIN_QUERIES_URI_ADD_USER_TO_GROUP = '/addUserToGroup';
export const ADMIN_QUERIES_URI_REMOVE_USER_FROM_GROUP = '/removeUserFromGroup';
export const ADMIN_QUERIES_URI_CONFIRM_USER_SIGN_UP = '/confirmUserSignUp';
export const ADMIN_QUERIES_URI_DISABLE_USER = '/disableUser';
export const ADMIN_QUERIES_URI_ENABLE_USER = '/enableUser';
export const ADMIN_QUERIES_URI_GET_USER = '/getUser';
export const ADMIN_QUERIES_URI_LIST_USERS = '/listUsers';
export const ADMIN_QUERIES_URI_LIST_GROUPS = '/listGroups';
export const ADMIN_QUERIES_URI_LIST_GROUPS_FOR_USER = '/listGroupsForUser';
export const ADMIN_QUERIES_URI_LIST_USERS_IN_GROUP = '/listUsersInGroup';
export const ADMIN_QUERIES_URI_SIGN_USER_OUT = '/signUserOut';


export const ROUTE_PATH_ANALYTICS = "analytics"
export const ROUTE_PATH_FILES = "files"
export const ROUTE_PATH_TEMPLATES = "templates"
export const ROUTE_PATH_USERS = "users"


export const USER_GROUP_ADMINS = "admins"
