import React, {createContext, useState} from 'react';

export const TenantContext = createContext({});

const TenantContextProvider = ({children}) => {
  const [tenant, setTenant] = useState({
      name: 'Demo',
      slug: 'demo'
  });

  return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <TenantContext.Provider
          value={{
            tenant,
            setTenant,
          }}
      >
        {children}
      </TenantContext.Provider>
  );
};

export default TenantContextProvider;