import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import RecursiveSidebarComponent from './RecursiveSidebarComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    color: '#727378',
    width: '100%',
    display: (props) => (props.open ? null : 'flex'),
    flexDirection: (props) => (props.open ? null : 'column'),
    alignItems: (props) => (props.open ? null : 'center'),
  },
}));

function SidebarComponent({open, loading, structure: {pages}}) {
  const classes = useStyles({open});

  if (loading) {
    return (
        <div className={classes.root}>
          Carregando...
        </div>
    );
  }

  if (!pages || pages.length <= 0) {
    return (
        <Typography className={classes.root}>
          Nenhum menu disponível
        </Typography>
    );
  }

  return (
      <div className={classes.root}>
        <RecursiveSidebarComponent open={open} pages={pages}/>
      </div>
  );
}

export default SidebarComponent;
