import getData from '../../resources/templates/demo_clothing_company/data';
import * as R from 'ramda';

function formatFakePromotion() {
  const data = getData();

  const series_result = R.groupBy(R.prop('LOJA'), data.promotion);

  const series_headers = R.keys(series_result);

  const series_data = R.values(series_result).map((products) => {
    const prediction = R.sum(products.map(product => product.PREDICAO));
    const suggestion = R.sum(products.map(product => product.SUGESTAO));

    return [
      prediction,
      suggestion,
    ];
  });

  return {
    credits: {
      enabled: false,
    },

    chart: {
      type: 'column',
    },
    title: {
      text: 'Total de abastecimento por lojas',
    },
    xAxis: {
      // categories: data.supply.map(supplier => supplier.name),
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Quantidade',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: series_headers.map((header, index) => {
      return {
        name: header,
        data: series_data[index],
      };
    }),
  };
}

export default formatFakePromotion;
