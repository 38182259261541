import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {createGlobalStyle} from 'styled-components';
import moment from 'moment';
import 'react-vis/dist/style.css';
import 'moment/locale/pt-br';
import userManager from './config/auth.config';
import {AuthProvider} from 'react-oidc-context';
import themeConfig from './config/theme.config';
import {ThemeProvider} from '@material-ui/core';

moment.locale('pt-br');

const RootStyled = createGlobalStyle`
    html, body, #root {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`;

ReactDOM.render(
    <AuthProvider userManager={userManager}>
      <RootStyled/>
      <ThemeProvider theme={themeConfig}>
        <App/>
      </ThemeProvider>
    </AuthProvider>,
    document.getElementById('root'),
);

serviceWorker.register();
