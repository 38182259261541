import React from "react";

function TemplatePage() {
  return (
    <div>
      <h1>Template Page</h1>
    </div>
  );
}

export default TemplatePage;
