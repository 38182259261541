import React, {useState} from 'react';
import {Container} from '@material-ui/core';
import TableComponent from '../components/TableComponent/TableComponent';
import CreateFileDialog from '../components/CreateFileDialog';

function FilePage() {
  const [files] = useState([]);

  return (
      <Container>
        <br/>
        <CreateFileDialog/>
        <br/>
        <br/>
        <TableComponent files={files}
                        items={files.map(({title, name, key, size, status}) => [
                          title, name, key, size, status,
                        ])} columns={[
          'Nome informado',
          'Nome arquivo',
          'Key',
          'Size (bytes)',
          'Status',
        ]}/>
      </Container>
  );
}

FilePage.propTypes = {};

export default FilePage;