import * as R from "ramda";

const getRecursivePages = (pages, parentPath = []) =>
  pages.map((i) => {
    const newParentPath = R.append(i.path, parentPath);

    if (i.pages && i.pages.length > 0) {
      return getRecursivePages(i.pages, newParentPath);
    }

    return { ...i, parentPath };
  });

export default (structure) => R.flatten(getRecursivePages(structure.pages));
