import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import getWidgetByType from "./getWidgetByType";
import {getGenericProvider} from "../../../providers/genericProvider";
import {FilterContext} from "../../../state/FilterContextProvider";
import * as R from "ramda";

function WidgetsComponent({widgetType, widgetParams, query, formatData, formatDataParams}) {
    const {variables, setVariables, filters, toVariables} = useContext(FilterContext);

    const filter = {variables, setVariables, filters}

    const [loading, setLoading] = useState(false);
    const [widgetData, setWidgetData] = useState(null);

    const offset = 0;
    const limit = 20;

    useEffect(() => {
        getData().then(() => {
        });
    }, R.values(filter));

    async function getData() {
        setLoading(true);
        try {
            if (!query) {
                if (formatData) {
                    const tableData = formatData({
                        filters: filter.variables,
                        formatDataParams,
                    })

                    setWidgetData(tableData);
                }

                setLoading(false);
                return;
            }

            const data = await getGenericProvider(query, {
                offset,
                limit,
                ...toVariables(),
            });

            console.log("WidgetsComponent - formatDataParams - getGenericProvider: ", formatDataParams)

            setWidgetData(formatData({
                filters: filter.variables,
                providerResult: data,
                formatDataParams,
            }));
        } catch (error) {
            console.error("getGenericProvider", error);
        }

        setLoading(false);
    }

    if (loading) {
        return <div>Carregando...</div>;
    }

    const Component = getWidgetByType(widgetType);

    return <Component {...widgetParams} data={widgetData}/>;
}

WidgetsComponent.propTypes = {
    widgetType: PropTypes.string.isRequired,
    widgetParams: PropTypes.object,
    query: PropTypes.string,
    formatData: PropTypes.func,
    formatDataParams: PropTypes.any,
};

export default WidgetsComponent;
