import React from 'react';
import {useAuth} from 'react-oidc-context';

function LoginCallbackPage() {
  const auth = useAuth();

  console.log('auth', auth);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    window.location.href = '/';
  } else {
    window.location.href = '/login';
  }

  return <div></div>;
}

export default LoginCallbackPage;
