import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Alert} from '@material-ui/lab';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Backdrop,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CreateFileDialog() {
  const classes = useStyles();

  // const FILE_PREFIX = 'raw';

  const validMimeTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ];

  const [open, setOpen] = useState(false);

  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setFile(null);
    setOpen(false);
  };

  const handleFileChange = async (event) => {
    setError(null);
    setFile(null);

    if (event?.target?.files?.length <= 0) {
      setError('Nenhum arquivo selecionado.');
      return;
    }

    const file = event?.target?.files[0];

    if (file) {
      if (validMimeTypes.includes(file.type)) {
        setFile(file);
      } else {
        setError(
            'Tipo de arquivo inválido. Apenas planilhas Excel e CSV são permitidas.');
      }
    }
  };

  const onSummit = async (e) => {
    e.preventDefault();

    setOpenLoading(true);

    if (file) {

      if (!title) {
        setError('Digite um nome para o arquivo.');
        return;
      }

      try {
        // const {attributes: {'custom:client_id': clientId}} = await Auth.currentAuthenticatedUser()
        //
        // const currentTimeInSeconds = Math.floor((new Date().getTime()) / 1000);
        //
        // const fileNameKey = `${FILE_PREFIX}/${currentTimeInSeconds}-${file.name}`
        //
        // const uploadedFile = await Storage.put(
        //     fileNameKey,
        //     file,
        //     {
        //         contentType: file.type,
        //         metadata: {
        //             versioned: 'true',
        //             tenantId: clientId
        //         },
        //         level: 'public'
        //     },
        // );

        // await API.graphql({
        //     query: createRawFile,
        //     variables: {
        //         input: {
        //             key: uploadedFile.key,
        //             tenantId: clientId,
        //             name: file.name,
        //             size: file.size,
        //             type: file.type,
        //             title: title,
        //             status: 'PENDING'
        //         }
        //     }
        // })
      } catch (error) {
        console.error('put file in storage: ', error);
        setError('Erro ao enviar arquivo.');
      }

      setError(null);
      setFile(null);
      setOpen(false);
    } else {
      setError('Nenhum arquivo selecionado.');
    }

    setOpenLoading(false);
  };

  return (
      <>
        <Typography variant="h4" component="h4">
          Envio de Arquivos
        </Typography>
        <Typography variant="body1" component="p">
          Envie arquivos com dados de series temporais para geracao de analises
          e de predicoes.
        </Typography>
        <br/>
        <br/>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Enviar arquivo
        </Button>
        <Dialog open={open} onClose={onclose}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Envio de arquivo
          </DialogTitle>
          <DialogContent>
            <Backdrop className={classes.backdrop} open={openLoading}>
              <CircularProgress color="inherit"/>
            </Backdrop>
            <form onSubmit={onSummit}>
              <DialogContentText>
                Envio de arquivo com dados de series temporais para geracao de
                analises e e predicoes.
              </DialogContentText>

              <TextField
                  autoFocus
                  margin="dense"
                  id="file-name"
                  label={'Digite um nome para o arquivo'}
                  type="text"
                  error={!!error}
                  fullWidth
                  required
                  onChange={(event) => setTitle(event.target.value)}
              />

              <br/>
              <br/>

              <Button
                  variant="contained"
                  component="label"
              >
                Selecionar Arquivo
                <input
                    type="file"
                    hidden
                    onInput={handleFileChange}
                />
              </Button>

              <br/>
              <br/>

              <div>
                {error &&
                    <Alert severity="error">{error}</Alert>
                }
                {file && (
                    <List dense={true} aria-label="mailbox folders">
                      <ListItem id="file-name-item">
                        <ListItemText
                            primary={'Nome do arquivo'}
                            secondary={file?.name}
                        />
                      </ListItem>
                      <Divider/>
                      <ListItem id="file-type-item">
                        <ListItemText
                            primary={'Tipo de arquivo'}
                            secondary={file?.type}
                        />
                      </ListItem>
                      <Divider/>
                      <ListItem id="file-size-item">
                        <ListItemText
                            primary={'Tamanho do arquivo'}
                            secondary={`${file?.size} bytes`}
                        />
                      </ListItem>
                    </List>
                )}
              </div>
              <DialogActions>
                <Button onClick={handleClose} disabled={openLoading}
                        color="primary">
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="contained"
                        disabled={openLoading}>
                  Enviar
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </>
  );
}
