import { createTheme } from "@material-ui/core";

export default createTheme({
  typography: {
    fontFamily: "Oswald",
  },
  palette: {
    primary: {
      light: "#576b82",
      main: "#2c4055",
      dark: "#021a2c",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffdd50",
      main: "#fcab10",
      dark: "#c47c00",
      contrastText: "#000",
    },
  },
});
