import React from 'react';
import {makeStyles, Tooltip, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {ROUTE_PATH_ANALYTICS} from '../../../defines';
import icons from '../../../icons';
import {Business} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  item: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  itemLabel: {
    display: "flex",
    alignItems: "center",
    height: 40,
    width: (props) => (props.open ? "100%" : 40),
    justifyContent: (props) => (props.open ? null : "center"),
    borderRadius: (props) => (props.open ? null : "50%"),
    "&:hover": {
      backgroundColor: theme.palette.divider,
    },
    "&:link, &:visited": {
      ccursor: "pointer",
      textDecoration: "none",
      color: "inherit",
    },
  },
  itemIcon: {
    margin: ({ open }) => (open ? "5px 8px 5px 0" : "5px 0"),
  },
}));

function LinkSidebarComponent({
  open,
  page: { name, path, icon },
  parentPath,
}) {
  const classes = useStyles({ open });

  const pathAll = `/${ROUTE_PATH_ANALYTICS}/${[...parentPath, path].join("/")}`;

  let IconComponent = Business;

  if (typeof icon === 'string') {
    if (icon in icons) {
      IconComponent = icons[icon];
    }
  } else if (icon) {
    IconComponent = icon;
  }

  return (
    <div className={classes.item}>
      <Link
        to={pathAll}
        style={{ paddingLeft: open ? 20 : null }}
        className={classes.itemLabel}
      >
        <Tooltip title={name}>
          <IconComponent className={classes.itemIcon} />
        </Tooltip>
        <Typography style={{ display: open ? null : "none" }}>
          {name}
        </Typography>
      </Link>
    </div>
  );
}

export default LinkSidebarComponent;
