import mapDataIE from "@highcharts/map-collection/countries/br/br-all.geo.json";

function truncate(value) {
  if (value < 0) {
    return Math.ceil(value);
  }

  return Math.floor(value);
}

function formatMapGraphPrediction({ Prediction: { MapStore } }) {
  const data = MapStore.map((i) => [i.Key, truncate(i.Quantity)]);

  return {
    chart: {
      map: "countries/br/br-all",
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    colorAxis: {
      min: 0,
    },

    series: [
      {
        data: data,
        name: "Quantidade",
        mapData: mapDataIE,
        states: {
          hover: {
            color: "#BADA55",
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    ],
  };
}

export default formatMapGraphPrediction;
