import axios from 'axios';
import {USER_TENANT_PAGES} from '../config/url.config';

export async function getAllPagesByTenant(token, tenantSlug) {
  const {data} = await axios.get(USER_TENANT_PAGES, {
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Tenant-Id': tenantSlug,
    },
  });

  return data;
}