export default () => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  xAxis: {
    title: {
      text: "<b>Eventos</b>",
    },
    categories: data.categories,
  },
  yAxis: {
    min: -100,
    max: 100,
    title: {
      text: "Porcentagem",
    },
    labels: {
      format: "{value}%",
    },
  },
  plotOptions: {
    column: {
      stacking: "normal",
      dataLabels: {
        enabled: true,
        format: "{point.y:.1f}%",
      },
    },
  },
  legend: {
    enabled: false,
  },
  series: [data.series],
});

const data = {
  categories: [
    "Queda da economia",
    "Publicidade do concorrente",
    "Desconto do concorrente",
    "Aumento do dólar",
    "Aumento da inflação",
    "Facebook",
    "Data comemorativa",
    "Linkedin",
    "Rádio",
    "Instagram",
    "Recepção na loja",
    "Congressos",
    "Seminários",
    "TV",
    "WhatsApp",
    "Promoção relâmpago",
  ],
  series: {
    data: [-56, -44, -32, -29, -2, 10, 12, 23, 27, 30, 32, 33, 34, 41, 43, 46],
    name: "Eventos",
  },
};
