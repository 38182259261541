import React, {useState} from 'react';
import TableComponent from '../components/TableComponent/TableComponent';
import {Container, Typography} from '@material-ui/core';

function UserPage() {

  const [users] = useState([]);

  return (
      <Container>
        <br/>
        <Typography variant="h4" component="h1" gutterBottom>
          Usuários
        </Typography>
        <br/>
        <br/>

        <TableComponent columns={[
          'ID',
          'Name',
          'Email',
          'Ativo',
          'Status',
          'Data de Criação',
        ]} items={users.map(
            ({Attributes, Enabled, UserCreateDate, UserStatus}) => [
              Attributes.filter(({Name}) => Name === 'sub')[0].Value,
              Attributes.filter(({Name}) => Name === 'name')[0].Value,
              `${Attributes.filter(({Name}) => Name === 'email')[0]
                  .Value} (${
                  Attributes.filter(
                      ({Name}) => Name === 'email_verified')[0].Value === 'true'
                      ? 'Verificado' : 'Não Verificado'
              })`,
              Enabled,
              UserStatus,
              UserCreateDate,
            ])}/>
      </Container>
  );
}

export default UserPage;
