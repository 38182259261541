import React, {useContext} from "react";
import FilterDateComponent from "./ComponentsByCategory/FilterDateComponent";
import FilterTableComponent from "./ComponentsByCategory/FilterTableComponent";
import {FilterContext} from "../../state/FilterContextProvider";

export const TYPE_CALENDAR = "calendar";
export const TYPE_TABLE = "table";
export const TYPES = {TYPE_CALENDAR, TYPE_TABLE};

function TabFilterComponent() {
    const {filters, filterItem, getFilterByValue} = useContext(FilterContext);

    if (filters.length <= 0) {
        return <></>;
    }

    const {type, ...filter} = getFilterByValue(filterItem);

    switch (type) {
        case TYPE_CALENDAR:
            return <FilterDateComponent {...filter} />;
        case TYPE_TABLE:
            return <FilterTableComponent value={filterItem} {...filter} />;
        default:
            return <></>;
    }
}

TabFilterComponent.propTypes = {};

export default TabFilterComponent;
