import Moment from "moment";
import {extendMoment} from "moment-range";
import SimpleLinearRegression from "ml-regression-simple-linear";

const moment = extendMoment(Moment);

const VALUE_ERROR = 12;

function formatFakeSalesWeek({filters}) {
    const {dateStart, dateEnd} = filters;

    const rangeReal = Array.from(
        moment
            .range(
                dateStart,
                moment().isAfter(moment(dateEnd)) ? dateEnd : Date.now()
            )
            .by("week")
    ).map((i) => i.format("DD/MM/YYYY"));

    const rangePrediction = Array.from(
        moment.range(dateStart, dateEnd).by("week")
    ).map((i) => i.format("DD/MM/YYYY"));

    const data = rangePrediction.map((i) => [i, Math.random() * 100]);
    const predictions = rangePrediction.map((pred, index) => {
        const item = data[index];

        const value = item[1] + Math.random() * 10;

        return value;
    });

    const xToRegression = data.map((d, i) => i);
    const yToRegression = data.map((d) => d[1]);

    const regression = new SimpleLinearRegression(xToRegression, yToRegression);
    const regressionData = regression.predict(xToRegression);

    const ranges = predictions.map((value) => {
        // const valueFromPercent = (value / 100) * VALUE_ERROR;
        const min = value - VALUE_ERROR;
        const max = value + VALUE_ERROR;

        return [min > 0 ? min : 0, max];
    });

    const series = [
        {
            name: "Predição",
            data: predictions,
        },
        {
            name: "Vendas",
            data: rangeReal.map((real, index) => {
                const item = data[index];

                return item[1];
            }),
        },
        {
            name: "Min/Max",
            data: ranges,
            type: "arearange",
            lineWidth: 0,
            // linkedTo: ":previous",
            // color: Highcharts.getOptions().colors[0],
            fillOpacity: 0.3,
            // zIndex: 0,
            marker: {
                enabled: false,
            },
        },
        {
            name: "Regressão",
            data: regressionData,
            color: "#ff0000",
        },
    ];

    return {
        credits: {
            enabled: false,
        },
        title: {
            text: "",
        },
        xAxis: {
            categories: rangePrediction,
        },
        yAxis: {
            title: {
                text: "Quantidade",
            },
        },
        tooltip: {
            shared: true,
            // pointFormat: "{series.name}: <b>{point.y}</b><br/>",
            valueDecimals: 0,
            crosshairs: true,
            // formatter: function () {
            //   return `
            //     <b>${this.key}</b> <br/>
            //     <span style="color: ${this.color};">\u25CF</span><b> ${
            //     this.series.name
            //   }: </b>${this.y.toFixed(0)} <br/>
            //   `;
            // },
        },
        plotOptions: {
            line: {
                // lineWidth: 4,
                // states: {
                //   hover: {
                //     lineWidth: 5,
                //   },
                // },
                marker: {
                    enabled: false,
                },
            },
        },
        series,
    };
}

export default formatFakeSalesWeek;
