import * as R from "ramda";
import moment from "moment";

export default ({
  Prediction: { Sale: predictions },
  Sale: { Week: sales },
}) => {
  const categories = R.uniq([
    ...sales.map((i) => i.Date),
    ...predictions.map((i) => i.Date),
  ]);

  const series = [
    {
      name: "Predição",
      data: categories.map((date) => {
        const result = R.find(R.propEq("Date", date))(predictions);

        if (!result) {
          return null;
        }

        return result.Quantity;
      }),
    },
    {
      name: "Vendas",
      data: categories.map((date) => {
        const result = R.find(R.propEq("Date", date))(sales);

        if (!result) {
          return null;
        }

        return result.Quantity;
      }),
    },
  ];

  return {
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories.map(
        (i) =>
          `${moment(i).format("MM/YYYY")}`
      ),
    },
    yAxis: {
      title: {
        text: "Quantidade",
      },
    },
    tooltip: {
      formatter: function () {
        return `
          <b>${this.key}</b> <br/>
          <span style="color: ${this.color};">\u25CF</span><b> ${
          this.series.name
        }: </b>${this.y.toFixed(0)} <br/>
        `;
      },
    },
    plotOptions: {
      line: {
        // lineWidth: 4,
        // states: {
        //   hover: {
        //     lineWidth: 5,
        //   },
        // },
        marker: {
          enabled: false,
        },
      },
    },
    series,
  };
};
