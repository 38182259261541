import getData from '../../resources/templates/demo_clothing_company/data';
import * as R from 'ramda';

function formatFakeProductionAndPurchase() {
  const data = getData();

  const series_headers = R.dropWhile(R.equals('PRODUTO'),
      R.values(R.mergeAll(data.suppliers.map(({headers}) => headers))));

  const series_data = data.suppliers.map(({products}) => {
    const average = R.sum(products.map(product => product.average));
    const prediction = R.sum(products.map(product => product.prediction));
    const quantity_purchase = R.sum(
        products.map(product => product.quantity_purchase));

    return [
      average,
      prediction,
      quantity_purchase,
    ];
  });

  return {
    credits: {
      enabled: false,
    },

    chart: {
      type: 'column',
    },
    title: {
      text: 'Total de compras por Fornecedor',
    },
    xAxis: {
      categories: data.suppliers.map(supplier => supplier.name),
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Quantidade',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: series_headers.map((header, index) => {
      return {
        name: header,
        data: series_data[index],
      };
    }),
  };
}

export default formatFakeProductionAndPurchase;
