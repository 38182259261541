import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  ContainerPageStyled,
  ContainerRowStyled,
} from "../components/GridComponent/GridComponent";
import {
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import WidgetComponent from "../components/WidgetComponent/WidgetComponent";
import { FilterContext } from "../state/FilterContextProvider";
import moment from "moment";

function GenericPage({ gridRowMin, gridRowMax, name, rows }) {
  const {
    variables: { dateStart, dateEnd },
  } = useContext(FilterContext);
  const theme = useTheme();
  const mediaQueryMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <div
        style={{
          height: 70,
          width: "calc(100%-10px)",
          padding: "10px 0 0 10px",
        }}
      >
        {mediaQueryMD ? (
          <Typography variant="h4" color="primary">
            {name}
          </Typography>
        ) : (
          <Typography variant="h5" color="primary">
            {name}
          </Typography>
        )}
        {dateStart && dateEnd ? (
          <Typography variant="h6" color="secondary">
            Do dia {moment(dateStart).format("DD/MM/YYYY")} ao dia{" "}
            {moment(dateEnd).format("DD/MM/YYYY")}
          </Typography>
        ) : null}
        <Divider />
      </div>
      <ContainerPageStyled
        rowsLength={rows.length}
        gridRowMin={gridRowMin}
        gridRowMax={gridRowMax}
      >
        {rows.map(({ widgets, ...row }, iRow) => {
          return (
            <ContainerRowStyled
              key={iRow}
              widgetsLength={widgets.length}
              {...row}
            >
              {widgets.map((widget, iWidget) => (
                <WidgetComponent key={iWidget} {...widget} />
              ))}
            </ContainerRowStyled>
          );
        })}
      </ContainerPageStyled>
    </>
  );
}

GenericPage.propTypes = {
  gridRowMin: PropTypes.string.isRequired,
  gridRowMax: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      rowSizeMin: PropTypes.string.isRequired,
      rowSizeMax: PropTypes.string.isRequired,
      columnSizeMin: PropTypes.string.isRequired,
      columnSizeMax: PropTypes.string.isRequired,
      widgets: PropTypes.array,
    })
  ),
};

export default GenericPage;
