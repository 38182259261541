import React from 'react';
import {createContext, useState} from "react";
import {formatProfileStructure} from "../formatdata";

export const StructureContext = createContext({});

function StructureContextProvider({children}) {
    const [structure, setStructure] = useState({});

    const setNewStructure = (structureString) => {
        const structure = structureString !== "" ? JSON.parse(structureString) : {};
        const structureFormatted = formatProfileStructure(structure);
        setStructure(structureFormatted);
    }
    
    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <StructureContext.Provider
            value={{
                structure,
                setStructure: setNewStructure,
            }}
        >
            {children}
        </StructureContext.Provider>
    )
}

export default StructureContextProvider;
