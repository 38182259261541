import Moment from "moment";
import {extendMoment} from "moment-range";
import SimpleLinearRegression from "ml-regression-simple-linear";

const moment = extendMoment(Moment);

const VALUE_ERROR = 12;

function formatFakeSalesMonth({filters}) {
    // const {dateStart, dateEnd} = filters;
    //
    // const rangeReal = Array.from(
    //     moment
    //         .range(
    //             dateStart,
    //             moment().isAfter(moment(dateEnd)) ? dateEnd : Date.now()
    //         )
    //         .by("month")
    // ).map((i) => i.format("DD/MM/YYYY"));
    //
    // const rangePrediction = Array.from(
    //     moment.range(dateStart, dateEnd).by("month")
    // ).map((i) => i.format("DD/MM/YYYY"));
    //
    // const data = rangePrediction.map((i) => [i, Math.random() * 100]);
    // const predictions = rangePrediction.map((pred, index) => {
    //     const item = data[index];
    //
    //     const value = item[1] + Math.random() * 10;
    //
    //     return value;
    // });
    //
    // const xToRegression = data.map((d, i) => i);
    // const yToRegression = data.map((d) => d[1]);
    //
    // const regression = new SimpleLinearRegression(xToRegression, yToRegression);
    // const regressionData = regression.predict(xToRegression);
    //
    // const ranges = predictions.map((value) => {
    //     const min = value - VALUE_ERROR;
    //     const max = value + VALUE_ERROR;
    //
    //     return [min > 0 ? min : 0, max];
    // });
    //
    // const series = [
    //     {
    //         name: "Predição",
    //         data: predictions,
    //     },
    //     {
    //         name: "Vendas",
    //         data: rangeReal.map((real, index) => {
    //             const item = data[index];
    //
    //             if (Array.isArray(item) && item.length > 1) {
    //                 return item[1];
    //             }
    //
    //             return []
    //         }),
    //     },
    //     {
    //         name: "Min/Max",
    //         data: ranges,
    //         type: "arearange",
    //         lineWidth: 0,
    //         fillOpacity: 0.3,
    //         marker: {
    //             enabled: false,
    //         },
    //     },
    //     {
    //         name: "Regressão",
    //         data: regressionData,
    //         color: "#ff0000",
    //     },
    // ];
    //
    // return {
    //     credits: {
    //         enabled: false,
    //     },
    //     title: {
    //         text: "",
    //     },
    //     xAxis: {
    //         categories: rangePrediction,
    //     },
    //     yAxis: {
    //         title: {
    //             text: "Quantidade",
    //         },
    //     },
    //     tooltip: {
    //         shared: true,
    //         valueDecimals: 0,
    //         crosshairs: true,
    //     },
    //     plotOptions: {
    //         line: {
    //             marker: {
    //                 enabled: false,
    //             },
    //         },
    //     },
    //     series,
    // };
    return {
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },

        // subtitle: {
        //     text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
        //     align: 'left'
        // },

        yAxis: {
            title: {
                text: 'Quantidade'
            }
        },

        xAxis: {
            accessibility: {
                rangeDescription: 'Range: 2010 to 2022'
            }
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2010
            }
        },

        series: [{
            name: 'Vendas',
            data: [
                24916, 37941, 29742, 29851, 32490, 30282,
                38121, 36885, 33726, 34243, 31050, 33099, 33473
            ]
        }, {
            name: 'Predição',
            data: [
                11744, 30000, 16005, 19771, 20185, 24377,
                32147, 30912, 29243, 29213, 25663, 28978, 30618
            ]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }

    }
}

export default formatFakeSalesMonth;
