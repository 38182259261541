import * as R from 'ramda';
import React, {createContext, useEffect, useState} from 'react';
import moment from 'moment-timezone';
import {TYPE_CALENDAR} from '../components/FilterComponents/TabFilterComponent';
import {formatProfileFilters} from '../formatdata';

export const FilterContext = createContext({});

const FilterContextProvider = (props) => {
  const [filters, setFilters] = useState([]);
  const [variables, setVariables] = useState({});
  const [filterItem, setFilterItem] = useState('dateStart');

  const setNewFilters = (filtersString) => {
    const filters = filtersString !== '' ? JSON.parse(filtersString) : [];
    setFilters(formatProfileFilters(filters));
  };

  useEffect(() => {
    setVariables(
        R.fromPairs(
            filters.map((i) => {
              switch (i.type) {
                case TYPE_CALENDAR:
                  const addDate = i['add_date'];
                  const addDateType = i['add_date_type'];
                  if (!R.isNil(addDate) && !R.isNil(addDateType)) {
                    return [
                      i.state,
                      moment().add(addDate, addDateType).toDate()];
                  }

                  return [i.state, moment().toDate()];
                default:
                  return [i.state, []];
              }
            }),
        ),
    );
  }, [filters]);

  function toVariables() {
    return R.fromPairs(
        filters.map((i) => {
          switch (i.type) {
            case TYPE_CALENDAR:
              return [
                i.state,
                moment
                    .utc(
                        moment(variables[i.state]).format('DD/MM/YYYY'),
                        'DD/MM/YYYY',
                    )
                    .format(),
              ];
            default:
              return [i.state, variables[i.state].map((i) => i.ID)];
          }
        }),
    );
  }

  const getFilterByValue = (value) => R.find(R.propEq('value', value))(filters);
  const getFilterFirst = () => R.head(filters);

  return (
      <FilterContext.Provider
          value={{
            variables,
            setVariables,
            filters,

            toVariables,

            setFilters: setNewFilters,
            getFilterByValue,
            getFilterFirst,

            filterItem,
            setFilterItem,
          }}
      >
        {props.children}
      </FilterContext.Provider>
  );
};

export default FilterContextProvider;
