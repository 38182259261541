export default `
  query(
    $dateStart: DateTime!
    $dateEnd: DateTime!
    $storeIDs: [Int]
    $colorIDs: [Int]
    $sizeIDs: [Int]
    $groupIDs: [Int]
    $offset: Int!
    $limit: Int!
  ){
    Prediction{
      Grid(
        dateStart: $dateStart
        dateEnd: $dateEnd
        storeIDs: $storeIDs
        colorIDs: $colorIDs
        sizeIDs: $sizeIDs
        groupIDs: $groupIDs
        offset: $offset
        limit: $limit
      ){
        Date
        Store
        Group
        Color
        Size
        Quantity
        CreatedAt
      }
    }
  }
`;
