export default `
  query(
    $dateStart: DateTime!
    $dateEnd: DateTime!
    $storeIDs: [Int]
    $clientIDs: [Int]
    $productIDs: [Int]
    $colorIDs: [Int]
    $sizeIDs: [Int]
    $groupIDs: [Int]
  ){
    Sale{
      BestSellingSize(
        dateStart: $dateStart
        dateEnd: $dateEnd
        storeIDs: $storeIDs
        clientIDs: $clientIDs
        productIDs: $productIDs
        colorIDs: $colorIDs
        sizeIDs: $sizeIDs
        groupIDs: $groupIDs
      ){
        Name
        NetAmount
      }
    }
  }
`;
